.throughput_container {
  display: flex;
  flex-direction: column;
}
.header {
  margin-bottom: 18px;
  line-height: 28.13px;
  span {
    margin-right: 4px;
    font-size: 24px;
    color: var(--count-light);
    font-weight: 600;
    font-family: Roboto, sans-serif;
  }
}

.period {
  color: var(--count-light);
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto, sans-serif;
  margin-bottom: 12px;
}

.chart_container {
  width: 100%;
}
.chart_legend {
  padding-left: 34px;
}

.yAxis_label {
  position: relative;
  & p {
    font-family: Roboto, sans-serif;
    color: var(--cancel-color);
    position: absolute;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin: 0;
    top: 13px;
    left: 17px;
  }
}
.periodPicker_title {
  color: var(--count-light);
  font-weight: 500;
  font-family: Roboto, sans-serif;
  line-height: 22.4px;
  margin: 12px 0 8px !important; // Перекрытие стилей от Project.module.scss
}

.emptyData {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 64px 0 16px;
  & p {
    color: var(--count-dark);
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
}
