@import 'src/scss/mixins';

.textDanger {
  color: #e04040;
  font-weight: 500;
}

.title {
  margin-bottom: 21px;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.subTitle {
  font-size: 16px;
  margin: 0 0 20px 0;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.basic {
  margin-top: 22px;
  margin-left: 20px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.gridLayout {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  @include for-tablet {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
  @include for-mobile {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}

.wrapper {
  margin-bottom: 5rem;
}

.rateBg {
  background: #f5f7fa;
}

.rateMain {
  background: #f5f7fa;
  padding: 20px;
  height: 511px;
  position: relative;
}

.listBenefits {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.radioGroup {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #dcdcdc;
}

.listBenefits li::before {
  content: '';
  display: flex;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background-image: url('../../assets/img/rates/done.svg');
  margin-right: 6px;
}

.itemBenefit {
  display: flex;
}

.btns {
  position: absolute;
  bottom: 24px;
  gap: 16px;
  @include for-mobile {
    display: flex;
    width: 85%;
  }
}

.titleCard {
  margin-top: 24px;
  margin-bottom: 28px;
  font-weight: 500;
}

.radioLabel {
  font-weight: 600 !important;
  font-size: 1.25rem !important;
  font-style: normal !important;
  line-height: 22px !important;
}

.optionTitle {
  margin-top: 4.5rem;
  margin-bottom: 2.5rem;
}
