.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  h2 {
    margin: 0;
    color: var(--count-light);
    font-size: 26px;
    line-height: 31px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.table {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.conditions {
  margin: 22px 0 28px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  > p {
    color: var(--count-light);
    margin: 0;
  }
}
