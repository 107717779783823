.customBar_textInside {
  transform-origin: center;
  fill: #fff;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 14.06px;
}
.customBar_textOutside {
  fill: var(--count-light);
  font-size: 14px;
  font-family: Roboto, sans-serif;
  line-height: 18px;
  font-weight: 500;
}
