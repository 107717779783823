@import 'src/scss/mixins';

.form__page__container {
  display: flex;
  flex-direction: row;
  gap: 40px;

  @media (max-width: 1280px) {
    flex-direction: column;
    padding: 0 25% !important;
  }

  @media (max-width: 1024px) {
    padding: 0 10% !important;
  }

  max-width: max-content;
}

.form__page__title {
  max-width: 212px;
  h2 {
    font-size: 26px;
  }
}

.form__card {
  margin-bottom: 3rem !important;
  margin-right: 1.5rem !important;
}

.form__wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  width: 416px;

  @media (max-width: 1280px) {
    width: 360px;
  }

  @media (max-width: 1024px) {
    width: 330px;
  }

  @media (max-width: 768px) {
    width: 300px;
  }

  @media (max-width: 640px) {
    width: 240px;
  }

  @media (max-width: 475px) {
    width: 200px;
  }

  @media (max-width: 375px) {
    width: 170px;
  }
}

.form__field {
  width: 100% !important;
  margin-bottom: 1.5rem !important;
}

.headId__field {
  margin-bottom: 1rem !important;
}

.stack__field {
  margin-top: 1.5rem !important;
}

.checkbox {
  margin-bottom: 2rem !important;
}

.submit__button {
  margin-bottom: 0.5rem !important;
}

.containerContent {
  height: 100%;
  margin-right: 1.5rem;
  margin-bottom: 55px;
}

.card {
  padding: 2rem 2rem 4rem 2rem;
  margin-bottom: 55px;
}

.titleScheduleName {
  margin-bottom: 29px;
}

.blockMenu {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: 700;
  line-height: 140%;
  margin-right: 40px;
  margin-bottom: 0;
}

.cardContent {
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  height: 100% !important;
  @include for-tablet {
    height: auto !important;
  }
}

.loadingBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnBlock {
  display: flex;
  gap: 26px;
}

.textName {
  font-weight: 500;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 22.4px;
}

.subTitle {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--count-dark);
}
