.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-bottom: 18px;

  color: var(--count-light);
  font-size: 24px;
  line-height: 28.13px;
  font-weight: 600;
  font-family: Roboto, sans-serif;
}

.period {
  color: var(--count-light);
  font-size: 16px;
  font-family: Roboto, sans-serif;
  line-height: 24px;
  margin-bottom: 12px !important; // Перекрытие стилей от Project.module.scss
}

.chart_container {
  width: 100%;
  margin-bottom: 14px;
}

.chart_legend {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  &__mark {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: #3babf0;
  }
  &__label {
    color: var(--count-light);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    font-family: Roboto, sans-serif;
  }
}

.emptyData {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 64px 0 16px;
  & p {
    color: var(--count-dark);
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
}
