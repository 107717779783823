@import 'src/scss/mixins';

.work__info {
  margin-right: 2.5rem;

  @include for-tablet {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  @include for-mobile {
    margin-left: 0;
  }
}

.work__info__title {
  font-weight: 700;
}

.status__block {
  display: flex;
  flex-direction: column;
  margin-top: 1.125rem;
  margin-bottom: 1.25rem;
}

.hour__price__block {
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  margin-bottom: 1.125rem;
}

.month__price__block {
  display: flex;
  flex-direction: column;
  margin-top: 1.125rem;
  margin-bottom: 1.5rem;
}

.status::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 7px;
  border-radius: 50%;
  background-color: #ff7223;
}

.status {
  font-weight: 500;
}

.work__info__content {
  font-weight: 500;
}
