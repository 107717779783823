@import 'src/scss/mixins';

.formAuth {
  @include for-mobile {
    border: none !important;
    min-width: 100% !important;
    padding: 24px 15px !important;
    margin-top: 0 !important ;
  }
}

.registration_form {
  display: flex;
  flex-direction: column;
  gap: 48px;

  &__agreement {
    display: flex;
    flex-direction: row;
    gap: 10px;

    &_checkbox {
      height: fit-content;
    }

    & p {
      font-size: 14px;
      margin: 0;
    }
  }
}
