@import 'src/scss/mixins';

.aboutMechanismBlock {
  padding: 54px 72px 100px;
  @include for-tablet {
    padding: 32px 36px;
  }
  @include for-mobile {
    padding: 26px 15px 49px 15px;
  }
  background: #fafafa;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.gridLayout {
  margin-top: 2.5rem;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  @include for-tablet {
    grid-template-columns: repeat(1, 1fr);
  }
  @include for-mobile {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.25rem;
  }
}

.gap1 {
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  gap: 1.5rem;
  @include for-mobile {
    gap: 1rem;
    margin-top: 1rem;
  }
}
