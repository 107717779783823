@import 'src/scss/mixins';

.aboutInfoBackground {
  background: url(../../assets/img/aboutInfo/background.png);
  background-size: cover;
  padding: 120px 72px;
  @include for-tablet {
    background-position: right;
    padding: 90px 0 105px 36px;
  }
  @include for-mobile {
    padding: 32px 15px;
  }
}

.aboutPlatform {
  max-width: 1440px;
  margin: 0 auto;
}

.aboutSigns {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutBlock {
  display: flex;
  justify-content: space-between;
  @include for-tablet {
    position: relative;
  }

  &__computer {
    @include for-tablet {
      position: absolute;
      right: 0;
    }
  }
}

.iconStyles {
  height: 455px;
  @include for-tablet {
    height: 350px;
  }
  @include for-mobile {
    display: none;
  }
}

.title {
  font-weight: 500;
  font-size: 38px;
  margin-bottom: 24px;
  font-family: Roboto, sans-serif;
  line-height: 45px;

  span {
    color: #007236;
  }

  @include for-tablet {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }
  @include for-mobile {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 12px;
  }
}

.hideBr {
  @include for-mobile {
    display: none;
  }
}

.descriptionBlock {
  color: var(--color-gray);
  font-weight: 400;
  font-size: 18px;
  line-height: 23.4px;
  margin-bottom: 56px;

  @include for-tablet {
    max-width: 60%;
    margin-bottom: 48px;
  }
  @include for-mobile {
    max-width: 100%;
    font-size: 16px;
    margin-bottom: 32px;
    line-height: 20.8px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  width: fit-content;

  @include for-tablet {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  @include for-mobile {
    gap: 22px;
  }

  &__connection {
    &:global(.MuiButtonBase-root) {
      padding: 14px 24px;
      line-height: 20px;

      &:hover {
        background-color: #21b84b;
      }

      @include for-tablet {
        width: 272px;
      }

      @include for-mobile {
        width: 260px;
      }
    }
  }

  &__solution {
    &:global(.MuiButtonBase-root) {
      padding: 14px 24px;
      line-height: 20px;

      &:hover {
        color: #ffffff;
      }

      @include for-tablet {
        width: 272px;
      }

      @include for-mobile {
        width: 260px;
      }
    }
  }
}
