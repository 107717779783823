.name {
  max-width: 300px;
}

.headerCheckbox {
  margin: 34px 0 0 -2px;
}

.headerButton {
  height: fit-content !important;
  margin-top: 34px !important;
}
