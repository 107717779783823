@import 'src/scss/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 1.5rem 1.5rem 1.5rem;
}

.nav__back {
  margin-top: 1.5rem;
}

.container {
  display: flex;
  flex-direction: row;
  padding: 2.5rem 2rem;

  h2 {
    font-weight: 700;
    font-size: 26px;

    @include for-tablet {
      margin-bottom: 2rem;
    }
  }

  @include for-tablet {
    flex-direction: column;
    align-items: center;
  }
}

.title {
  margin-right: 2.5rem;

  h2 {
    color: #39393a !important;
  }
}

.edit__form {
  max-width: 416px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form__item {
  width: 100% !important;
  margin-bottom: 1.5rem !important;
}

.form__cloud {
  margin-bottom: 2.5rem;
}

.sumbit__button {
  max-width: 140px !important;
  margin-bottom: 1rem !important;
}

.bottom__text {
  color: #78858b;
}

.visibility {
  display: flex;
  align-items: baseline;
  gap: 11px;
  position: relative;
  &__tooltip {
    position: absolute;
    top: 19px;
    right: -30px;
  }
}
