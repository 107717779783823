@import 'src/scss/mixins';

.requisites_form {
  padding: 40px 32px;
  background-color: #fff;
  @include for-tablet {
    margin-right: 15px;
  }
  @include for-mobile {
    margin-right: 15px;
  }
}
.reqForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.field {
  width: 100%;
}

.cancelBtn {
  color: #6c757d;
  :hover {
    color: #fff;
  }
}

.requisites_card {
  background-color: white;
  padding: 24px 32px;
  border-radius: 12px;
  border: 1px solid #f0f0f0;
}

.requisites_block {
  display: flex;
  @include for-tablet {
    flex-direction: column;
  }
  @include for-mobile {
    flex-direction: column;
  }
}
