@import 'src/scss/mixins';

.profile__role__wrapper {
  display: grid;
  grid-template-columns: 2fr 7fr;
  margin-right: 1.5rem;

  @include for-mobile {
    grid-template-columns: 1fr;
    padding: 0 1.5rem;
  }
}
.profile__role__title {
  padding-left: 2rem;
  padding-top: 2.5rem;
  margin-right: 3rem;

  & h3 {
    font-weight: 700;
  }

  @include for-mobile {
    padding-left: 0px;
  }
}

.profile__role__description {
  line-height: 24px;
  margin: 6px 0 0;
  margin-right: 2rem;
}

.profile__role__checkbox_description {
  margin: 0 0 0 12px;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
}
.profile__role__form {
  margin: 2rem 0;

  @include for-mobile {
    margin: 1.5rem 0;
  }
}
.profile__role__form_container {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
