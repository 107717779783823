.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  p {
    margin: 0;
    color: var(--count-light);
  }
}

.links {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  a {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    border: 1px solid var(--border-gray);
    border-radius: 4px;
    padding: 10px 12px;
    img {
      height: 52px;
      width: 52px;
    }
  }
}
