@import 'src/scss/mixins';

.gantt__chart__container {
  margin-right: 2.5rem;
  width: 100%;
  display: flex;

  @media (max-width: 1250px) {
    margin-right: 1rem;
  }

  @include for-tablet {
    margin-right: 0;
  }
}

.customBar_textInside {
  fill: #fff;
  font-weight: 700;
  font-size: 14px;
}

.customBar_textOutside {
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}
