@import 'src/scss/mixins';

.card__block {
  display: grid;
  grid-template-columns: 165px 165px;
  gap: 1rem;
  margin-right: 40px;

  @include for-tablet {
    margin-bottom: 2rem;
  }
}
