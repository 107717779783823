.status__list {
  padding-left: 0;
  width: 140px;

  li {
    color: var(--bg-green);
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 1rem;
  }

  .status__item {
    display: flex;
    align-items: center;
  }
}

span {
  cursor: pointer;
}

.status__color {
  min-width: 1rem;
  height: 1rem;
  width: 1rem;
  border-radius: 4px;
  margin-right: 0.375rem;
}
