@font-face {
  font-family: 'Roboto, sans-serif';
  src: local('Roboto'), url('../../assets/fonts/Roboto-Regular.ttf');
}

.color-green {
  color: #007236;
}

.color-dark-yellow {
  color: #eeba00;
}

.color-light-blue {
  color: #4ca0e0;
}

.border-turquoise-1 {
  border: 1px solid #40e0d0;
}

.border-dark-yellow-1 {
  border: 1px solid #eeba00;
}

.border-light-blue-1 {
  border: 1px solid #4ca0e0;
}

.bg-cyan {
  background: #40e0d0;
}
.color-gray {
  color: #a7a7a7;
}

.color-green_hover {
  &:hover {
    color: #21b84b;
    cursor: pointer;
  }
}

.color-black {
  color: #39393a;
}
