.title {
  color: var(--count-light);
  font-size: 24px;
  font-family: Roboto, sans-serif;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 18px;
}

.yLabel {
  position: relative;
  & p {
    position: absolute;
    color: var(--cancel-color);
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
  }
}
