.input {
  position: relative;
}

.form__wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  background: var(--bg-light);
  border-radius: 4px;
  position: relative;

  h2.label {
    font-weight: 700;
    font-size: 26px;
    color: var(--count-light);
  }

  h2.thin__label {
    font-size: 16px;
    font-weight: 500;
  }
}

.form__wrapper.dark {
  background: var(--bg-dark);

  h2.label.dark {
    color: var(--count-dark);
  }
}

.small__modal {
  padding: 32px 32px !important;
}
