.form__page__container {
  display: flex;
  flex-direction: row;

  @media (max-width: 1280px) {
    flex-direction: column;
    padding: 0 25% !important;
  }

  @media (max-width: 1024px) {
    padding: 0 10% !important;
  }

  max-width: max-content;
}

.form__page__title {
}

.form__card {
  margin-bottom: 3rem !important;
  margin-right: 1.5rem !important;
}

.form__wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  width: 416px;

  @media (max-width: 1280px) {
    width: 360px;
  }

  @media (max-width: 1024px) {
    width: 330px;
  }

  @media (max-width: 768px) {
    width: 300px;
  }

  @media (max-width: 640px) {
    width: 240px;
  }

  @media (max-width: 475px) {
    width: 200px;
  }

  @media (max-width: 375px) {
    width: 170px;
  }
}

.form__field {
  width: 100% !important;
  margin-bottom: 1.5rem !important;
}

.headId__field {
  margin-bottom: 1rem !important;
}

.stack__field {
  margin-top: 1.5rem !important;
}

.checkbox {
  margin-bottom: 2rem !important;
}

.submit__button {
  margin-bottom: 0.5rem !important;
}

.infrastructureLink {
  color: var(--bg-green);
  &:hover {
    color: var(--text-light-green);
  }
}

.detailInfrastructureMain {
  margin-top: 16px;
}

.detailInfrastructure {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.detailInfrastructureTitle,
.detailInfrastructureResult {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.detailInfrastructureTitle {
  font-weight: 400;
  line-height: 24px;
  color: var(--count-dark);
}

.detailInfrastructureResult {
  font-weight: 500;
  line-height: 22.4px;
  color: var(--count-light);
}

.btnsInfrastructure {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.btnCancel {
  font-family: Roboto, sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 10px 20px !important;
  color: var(--cancel-color) !important;
  &:hover {
    background-color: transparent !important;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}
