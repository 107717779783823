.tagList {
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: 40px;
  padding: 2px 20px 10px 20px;
  overflow: hidden;
}

.tag {
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 7px 8px;
  font-size: 14px;
  color: #39393a;
  transition: all 0.3s ease;
  text-align: center;
  outline: 1px solid white;
  white-space: nowrap;

  &:hover {
    background-color: #f0f0f0;
    outline: 1px solid #21b84b;
    color: #39393a;
  }

  &:active {
    outline: 1px solid #148c36;
  }
}

@media (max-width: 768px) {
  .tagList {
    overflow-x: scroll;
  }
}

@media (max-width: 576px) {
  .tagList {
    margin-bottom: 1rem;
  }
}
