@import 'src/scss/mixins';

.pie__block {
  height: max-content;
  border: solid 1px var(--border-gray);
  border-radius: 3px;
  padding: 1rem 1.5rem;
  height: 316px;

  &__title {
    margin-bottom: 1rem;
    color: #39393a;
    font-weight: 700;
    font-size: 26px;
  }

  &__content {
    display: flex;
    flex-direction: row;
  }

  @include for-mobile {
    height: auto;
  }
}
