.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 29px;
  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    &_cancel {
      color: var(--cancel-color) !important;
      &:hover {
        color: var(--cancel-color) !important;
        background-color: transparent !important;
      }
    }
  }
  &__fields {
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__checkboxField {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      p {
        color: var(--count-light);
        font-weight: 500;
        line-height: 24px;
        margin: 0;
      }
    }
  }
}

.hidden {
  display: none !important;
}

.addPositionBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  p {
    margin: 0;
    color: var(--text-default-color);
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }
}
