@import 'src/scss/mixins';
@import 'src/scss/variables';

.input {
  &__container {
    max-width: 416px;
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
      color: $c-gray-0;
      margin: 0;
      max-width: 312px;
    }
  }

  &_active {
    border: 1px solid rgba(#000b26, 0.04);
    background: rgba(#000b26, 0.04);
  }

  &__text {
    color: $c-gray-1;
    max-width: 300px;
  }

  &__button {
    padding: 12px 24px;
    border: 1px solid $c-gray-1;
    color: $c-gray-1;
    background: none;
    transition: 0.5s;
  }

  &:hover {
    background: $c-gray-0;
    color: white;
  }

  & input {
    display: none;
  }
}

.drag_drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $c-light-gray;
  border-radius: 8px;
  border: 1px dashed rgba(#000b26, 0.16);
  padding: 24px 20px;
  transition: 0.5s;
  text-align: center;

  &__button {
    background: none;
    transition: 0.5s;
    color: $c-turquoise;
    border: none;
  }

  &_active {
    border: 1px solid rgba(#000b26, 0.04);
    background: rgba(#000b26, 0.04);
  }

  & span {
    color: var(--text-default-color);
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  & input {
    display: none;
  }

  & p {
    color: #78858b;
  }
}

.file {
  margin-top: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 6px;
  p {
    color: $c-black;
    font-size: 16px;
    margin-bottom: 0;
  }

  &__wrapper {
    margin-top: 10px;

    p {
      font-size: 12px;
    }
  }
  &__button {
    background: none;
    border: none;
    margin-left: 8px;
  }
}
