@mixin f-125 {
  font-size: 16px;
  font-weight: 500;
}

@mixin f-171 {
  font-weight: 500;
  font-size: 14px;
}

@mixin f-111 {
  font-weight: 600;
  font-size: 18px;
}

@mixin text-12 {
  font-weight: 400;
  font-size: 16px;
}

@mixin for-tablet {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin for-mobile {
  @media (max-width: 767px) {
    @content;
  }
}
