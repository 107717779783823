@import 'src/scss/mixins';

.main {
  display: flex;
  @include for-mobile {
    flex-direction: column;
  }
}

.title,
.description {
  margin: 0;
  color: var(--count-light);
}

.title {
  font-weight: 500;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 12px;
}

.description {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}
