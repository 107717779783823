.icon__button {
  margin: 0 0.5rem;
}

.approveIcon,
.playCircleOutlineIcon,
.closeIcon,
.playArrowIcon,
.stopIcon {
  &:hover {
    opacity: 0.5;
  }
}

.playCircleOutlineIcon {
  color: var(--border-blue);
}

.playArrowIcon {
  color: var(--light-green);
}

.stopIcon {
  color: var(--yellow-light);
}
