.container {
  border: none !important;
}

.radio {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.checkbox {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.form__direction {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.form__stack {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
