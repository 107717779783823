.containerContent {
  height: 100%;
  margin-right: 1.5rem;
  margin-bottom: 55px;
}

.resource__detail {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  }

  &__title {
    font-family: Roboto;
    font-size: 26px;
    font-weight: 700;
    line-height: 31.2px;
  }

  &__edit__button {
    color: #78858b !important;
  }

  &__cards {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2rem;

    &__1 {
      width: 100%;
      padding: 1.5rem;
    }

    &__2 {
      width: 100%;
      padding: 1.5rem;
    }

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
}

.loadingBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.attribute__block {
  display: flex;
  margin-bottom: 1.5rem;
  flex-direction: column;

  &__title {
    color: var(--count-dark);
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &__value {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }
}
