@import '../../scss/mixins';

.university__page {
  h1,
  h2,
  p {
    font-family: Roboto, sans-serif;
  }

  //h2 {
  //  color: var(--text-hover-color);
  //  font-size: 32px;
  //  line-height: 39px;
  //  font-weight: 500;
  //  margin: 0;
  //  @include for-mobile {
  //    font-size: 26px;
  //    line-height: 31.2px;
  //  }
  //}
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.titleUniversity {
  color: var(--text-default-color);
  font-size: 32px;
  line-height: 39px;
  font-weight: 500;
  margin: 0;
  @include for-mobile {
    font-size: 26px;
    line-height: 31.2px;
  }
}

.mainInfoBg {
  background: url(../../assets/img/university/mainBg.png);
  background-size: cover;
  padding: 46px 52px 38px 72px;
  @include for-tablet {
    padding: 92px 36px 168px;
  }

  @include for-mobile {
    background-position: center;
    padding: 68px 15px 69px;
  }
}

.mainInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 94px;
  @media screen and (max-width: 1081px) {
    justify-content: center;
    gap: 0;
  }
  &__block {
    display: flex;
    flex-direction: column;
    gap: 59px;
    &_text {
      font-size: 30px;
      line-height: 39px;
      font-weight: 500;
      margin: 0;
      span {
        color: var(--text-default-color);
      }
      @media screen and (max-width: 1081px) {
        font-size: 36px;
        line-height: 48.8px;
      }
      @include for-mobile {
        font-size: 26px;
        line-height: 31.2px;
      }
    }
    &_btn {
      width: fit-content;
      padding: 14px 24px !important;
      font-size: 16px !important;
      line-height: 20px;
    }
  }
  &__img {
    @media screen and (max-width: 1081px) {
      display: none;
    }
  }
}

.reasonsMain {
  padding: 64px 76px 70px 72px;
  @include for-tablet {
    padding: 56px 36px 48px 36px;
  }
  @include for-mobile {
    padding: 32px 15px 36px;
  }
}

.reasons {
  display: flex;
  flex-direction: column;
  gap: 39px;

  &__list {
    display: flex;
    flex-direction: row;
    gap: 28px;
    @include for-tablet {
      flex-direction: column;
      gap: 24px;
    }
    @include for-mobile {
      gap: 14px;
    }
    &__block {
      display: flex;
      flex-direction: column;
      gap: 34px;
      @include for-tablet {
        gap: 24px;
      }
      @include for-mobile {
        gap: 14px;
      }
      &_item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 12px;
        &_number {
          margin: 0;
          color: var(--text-default-color);
          font-size: 28px;
          line-height: 32.81px;
          font-weight: 600;
        }
        &_text {
          color: var(--count-light);
          margin: 0;
          font-size: 16px;
          line-height: 22.4px;
        }
      }
    }
  }
}

.benefitsMain {
  padding: 0 72px 59px 72px;
  @include for-tablet {
    padding: 0 36px 40px;
  }
  @include for-mobile {
    padding: 0 15px 40px;
  }
}

.benefits {
  display: flex;
  flex-direction: column;
  gap: 23px;
  &__row {
    display: flex;
    flex-direction: row;
    gap: 24px;
    @media screen and (max-width: 1370px) {
      flex-direction: column;
    }
    &__block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 18px;
      &_header {
        color: var(--count-light);
        margin: 0;
        line-height: 22px;
        font-size: 20px;
        font-weight: 600;
      }
      &_list {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
    &__peopleBlock {
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 416px;
      @media screen and (max-width: 1370px) {
        max-width: none;
      }
      &__item {
        display: flex;
        flex-direction: column;
        gap: 18px;
        &_header {
          color: var(--count-light);
          margin: 0;
          line-height: 22px;
          font-size: 20px;
          font-weight: 600;
        }
        &_list {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
      }
    }
  }
}

.yandexPartnershipMain {
  padding: 0 72px 48px;
  @include for-tablet {
    padding: 0 36px 48px;
  }
  @include for-mobile {
    padding: 0 15px 32px;
  }
}

.yandexPartnership {
  background: url(../../assets/img/university/yandexBg.png);
  background-size: cover;
  border-radius: 12px;
  padding: 41px 0 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include for-tablet {
    padding: 58px 24px 74px 20px;
  }
  @include for-mobile {
    padding: 20px 14px 21px 15px;
  }
  &_text {
    font-size: 32px;
    line-height: 44.8px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    width: 70%;
    letter-spacing: -0.005em;
    margin: 0;
    @include for-tablet {
      font-size: 24px;
      line-height: 28.13px;
      width: 100%;
      font-weight: 600;
    }
    @include for-mobile {
      font-size: 18px;
      line-height: 23.4px;
      letter-spacing: normal;
    }
  }
}

.yandexDiscountMain {
  padding: 0 72px 107px;
  @include for-tablet {
    padding: 0 29px 64px 36px;
  }
  @include for-mobile {
    padding: 0 15px 48px;
  }
}

.yandexDiscount {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  @include for-tablet {
    gap: 16px;
  }
  @include for-mobile {
    gap: 8px;
  }
  &_text {
    color: var(--count-light);
    font-weight: 600;
    font-size: 36px;
    line-height: 50.4px;
    text-align: center;
    margin: 0;
    span {
      color: #fc3f1d;
    }
    @include for-mobile {
      font-weight: 500;
      font-size: 26px;
      line-height: 31.2px;
    }
  }
  &_subtext {
    color: var(--count-light);
    font-size: 24px;
    font-weight: 600;
    line-height: 28.13px;
    letter-spacing: -0.005em;
    text-align: center;
    margin: 0;
    @include for-mobile {
      font-size: 16px;
      line-height: 22.4px;
      letter-spacing: normal;
    }
  }
}

.formMain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 116px;
  @include for-tablet {
    padding: 0 36px 100px;
  }
  @include for-mobile {
    padding: 0 15px 100px;
  }
}

.title__color {
  color: var(--text-hover-color);
}
