@import 'src/scss/mixins';

.action__buttons {
  display: flex;
  flex-direction: row;

  @include for-tablet {
    flex-direction: column;
  }
}

.icon__button {
  margin: 0 0.5rem;
}
