@import '../../scss/mixins';

.formMain {
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 124px;
  border: 1px solid #a7a7a7;
  border-radius: 12px;
  width: 70%;
  @include for-tablet {
    gap: 0;
    width: 100%;
  }
  &__container {
    display: flex;
    padding: 32px 0 40px 48px;
    flex-direction: column;
    gap: 36px;
    width: 100%;
    @include for-tablet {
      gap: 25px;
      padding: 24px 40px 40px;
      width: 100%;
    }
    @include for-mobile {
      gap: 24px;
      padding: 16px 15px 32px;
    }
    &_text {
      margin: 0;
      color: var(--text-default-color);
      font-size: 36px;
      line-height: 50.4px;
      font-weight: 500;
      @include for-tablet {
        line-height: 43.2px;
      }
      @include for-mobile {
        font-size: 26px;
        line-height: 31.2px;
      }
    }

    &__agreement {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-bottom: 16px;

      &_checkbox {
        height: fit-content;
      }

      & p {
        font-size: 14px;
        margin: 0;
      }
    }
  }

  &__img {
    border-radius: 12px;
    @include for-tablet {
      display: none;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;
  &__actions {
    display: flex;
    flex-direction: row;

    gap: 40px;
    align-items: center;
    @include for-tablet {
      flex-direction: column;
      gap: 20px;
    }
    &__success_text {
      color: var(--text-hover-color);
      margin: 0;
      font-size: 16px;
    }
  }
  &_btn {
    font-size: 16px !important;
    line-height: 20px !important;
    padding: 14px 24px !important;
    @include for-tablet {
      width: 100%;
    }
  }
}
