.intervalSelect_container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 24px;
}

.intervalSelect_input {
  width: 72px;
  & input {
    text-align: center;
  }
  & fieldset {
    top: 0px;
  }
}
.intervalSelect_select {
  max-width: 161px;
}
