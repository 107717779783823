.header {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  color: #39393a;
  margin-bottom: 0;
}
.bottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1.5rem;
  margin-top: 3.5rem;
}
.buttonChoose {
  border-radius: 0.25rem;
  padding: 0.625rem 1.25rem;
  background-color: #199b3e;
  border-color: transparent;
  color: #fff;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.125rem;
  input[type='file'] {
    display: none;
  }
  &:hover {
    background-color: #21b84b;
  }
  &:active {
    background-color: #148c36;
  }
}
.buttonCancel {
  border: none;
  background-color: transparent;
  color: #78858b;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.125rem;
}
.photo_modal {
  background: #fff;
  padding: 2rem;
  border-radius: 4px;
}
.photoContainer {
  margin-top: 1.5rem;
  display: flex;
  gap: 1.5rem;
}
.cropper {
  position: relative;
  width: 12.5rem;
  height: 12.5rem;
}

.preview_container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.errorMessage {
  color: #e04040;
  font-size: 0.875rem;
  line-height: 140%;
  margin-right: auto;
  margin-bottom: 0;
}
