.item {
  margin-bottom: 56px;

  & a {
    color: #39393a;
  }
}

.image {
  border-radius: 3px;
  min-height: 210px;
  width: 306px;
}

.tag {
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 7px 8px;
  font-size: 14px;
  color: #39393a;
  transition: all 0.3s ease;
  text-align: center;
  outline: 1px solid white;
  white-space: nowrap;

  &:hover {
    background-color: #f0f0f0;
    outline: 1px solid #21b84b;
    color: #39393a;
  }

  &:active {
    outline: 1px solid #148c36;
  }
}

.anons {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.date {
  color: #78858b;
}

@media (max-width: 768px) {
  .item {
    margin-bottom: 48px;
  }

  .image {
    min-height: 176px;
    width: 214px;
  }

  .date {
    font-size: 14px;
    margin-top: 10px;
  }
}

@media (max-width: 576px) {
  .item {
    margin-bottom: 20px;
  }
  .image {
    min-height: 200px;
    width: 100%;
  }
}
