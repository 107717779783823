.form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.button__cancel {
  color: var(--cancel-color) !important;
  margin-right: 0.5rem !important;
}

.sys_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}
