.icon {
  width: 180px;
  height: 180px;
  border-radius: 4px;
  cursor: pointer;
}

.removeButton {
  position: relative;
  background: none;
  border: none;
  top: -175px;
  left: 146px;
}

.removeButton:hover svg path {
  fill: #e04040;
}
