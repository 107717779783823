.modal {
  display: flex;
  flex-direction: column;
}

.modal__button {
  color: var(--count-dark);
  padding: 14px 14px;
  font-weight: 500;
}
