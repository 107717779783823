.header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 4;
  border-bottom: 1px solid var(--border-bottom-light);
  background: var(--bg-light);
  @media (max-width: 576px) {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    :global(.nav_R) > * {
      width: auto;
    }
  }
}

.header.dark {
  border-bottom: 1px solid var(--border-bottom-dark);
  background: var(--bg-dark);
}

.menu__button {
  font-weight: 500 !important;
  height: 3rem !important;

  &:hover {
    background-color: var(--hover-lite) !important;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #dcdcdc;
  }
  & a {
    text-decoration: none;
    color: var(--count-light);
  }
}

.menu__item {
  width: 110px;
  margin-left: 0.5rem;
}
