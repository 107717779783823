.budget__analytics {
  margin-top: 4rem;

  &__title {
    margin-bottom: 1rem;

    h2 {
      font-weight: 600;
      font-size: 24px;
      font-family: Roboto, sans-serif;
    }
  }
}
