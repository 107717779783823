.icon__button {
  margin: 0 0.5rem;
}

.playCircleOutlineIcon:hover {
  opacity: 0.5;
}

.playCircleOutlineIcon {
  color: var(--border-blue);
}

.playArrowIcon:hover {
  color: var(--light-green);
}

.stopIcon:hover {
  color: var(--yellow-light);
}
