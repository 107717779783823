@import 'src/scss/mixins';

.profile__about__me__wrap {
  display: grid;
  grid-template-columns: 5fr 8fr 10fr;
  margin-top: 1.5rem;
  margin-right: 1.5rem;

  @media (max-width: 1536px) {
    grid-template-columns: 4fr 8fr 8fr;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 3fr 8fr 6fr;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 3fr 8fr 4fr;
  }

  @include for-mobile {
    grid-template-columns: 1fr;
    padding: 0 1.5rem;
  }
}

.profile__about__me__title {
  margin-right: 2.5rem;
  margin-top: 2rem;
  margin-left: 2rem;

  & h3 {
    font-weight: 700;
  }

  @include for-mobile {
    margin-left: 0px;
  }
}

.profile__about__me__avatar {
  margin-top: 2rem;
}

.profile__about__me__form {
  margin-right: 2.5rem;
  margin-top: 2rem;
  margin-left: 2rem;

  & h3 {
    font-weight: 700;
  }

  @include for-mobile {
    margin-left: 0px;
  }
}

.form_field {
  margin-bottom: 1.5rem !important;
}

.footer {
  margin-bottom: 3rem;
}
