@import '../../scss/mixins';

.activeLink {
  color: #39393a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  &:hover,
  &.active {
    color: var(--text-hover-color);
    cursor: pointer;
  }
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.modes {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  @include for-mobile {
    overflow: auto;
  }
}
