.modalTitle {
  font-weight: 700;
  font-size: 26px;
  line-height: 140%;
  margin-bottom: 16px !important;
  margin-top: 0.5rem;
  margin-left: 1rem;
}

.title {
  font-weight: 700;
  font-size: 26px;
  line-height: 170%;
  margin-bottom: 0;
}

.subTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 170%;
  margin-bottom: 0;
}

.colorCyan {
  color: #40e0d0 !important;
}

.linkButton {
  border: none;
  background-color: transparent;
}

.pageContainer {
  padding: 24px;
}

.commonCard {
  background-color: white;
  padding: 32px;
  border-radius: 12px;
  border: 1px solid #f0f0f0;

  &Label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #a7a7a7;
    margin: 20px 0 0 0;
  }

  &Value {
    font-size: 16px;
    line-height: 140%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 1.5rem;
}

.navigation {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin-bottom: 10px;

  &Item {
    padding-right: 1.75rem;
    white-space: nowrap;

    &Link {
      color: #78858b;
      text-decoration: none;

      &:hover {
        background: transparent;
        color: #40e0d0;
        border-bottom: 1px solid #40e0d0;
        padding-bottom: 12px;
        border-radius: 0;

        path {
          fill: #40e0d0;
        }
      }
    }
  }
}

.editButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  border: 1px solid #40e0d0;
  border-radius: 8px;
  color: #40e0d0;
  font-size: 14px;
  background-color: transparent;
  transition: all 0.3s ease;

  &Icon {
    transition: stroke 0.3s ease;
    width: 16px;
    height: 16px;
    stroke: #40e0d0;
  }
}

.addTeamButton {
  border: none;
  color: #199b3e;
  font-weight: 500;
  background-color: transparent;
}

.cancelAddTeamButton {
  border: none;
  background-color: transparent;
  margin-bottom: 0.5rem !important;
  color: #78858b !important;
  margin-right: 0.5rem !important;
  &:hover {
    color: var(--bg-light) !important;
  }
}
.submitAddTeamButton {
  margin-right: 1rem !important;
  margin-bottom: 0.5rem !important;
}

.customerUpdate {
  display: flex;
  flex-wrap: wrap;

  &Title {
    max-width: 210px;
    margin-right: 40px;
  }

  &Form {
    width: 100%;
    max-width: 416px;
  }
}

.manager {
  &Avatar {
    border-radius: 50%;
    width: 52px;
    height: 52px;
    margin-right: 8px;
  }
}

.teamName {
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 9px;
}

.deleteButton {
  padding: 0 !important;
  border-radius: 50% !important;
  background: transparent;
}

.no__integration__message {
  margin-top: 2rem;
  max-width: 500px;
}

.headTeam {
  padding: 24px 32px 17px 32px;
}

.teamLabel {
  color: var(--count-light);
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 26px;
}

.formWrapper {
  background: var(--bg-light);
}

.divider {
  background-color: var(--buttonLightTheme) !important;
}

.formControl {
  margin-left: 24px !important;
}

.controlLabel:hover {
  background: var(--hover-lite);
}

.emptyBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-light-gray);
  padding: 10px 0;
}
