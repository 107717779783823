.item {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .section {
    margin-bottom: 0.75rem;

    .title {
      font-size: 12px;
      color: var(--cancel-color);
      margin-bottom: 2px;
    }
  }
}
