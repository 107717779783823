.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  h4 {
    color: var(--count-light);
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin: 0;
  }
  &__addButton {
    max-width: 170px;
  }
}

.executor {
  text-decoration: none;
}

.buttons {
  display: inline-flex;
  align-items: center;
  gap: 16px;
}

.more {
  position: relative;

  > div {
    position: absolute;
    top: -24px;
    left: -13px;
  }
  &_option {
    color: var(--count-light) !important;
    font-weight: 400;
    &:hover {
      color: var(--count-light) !important;
      background: #199b3e0d !important;
    }
  }
}
