@import 'src/scss/mixins';

.ws_header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;

  @include for-tablet {
    overflow: auto;
  }

  @include for-mobile {
    overflow: auto;
  }
}

.ws_navigation {
  display: flex;
  list-style-type: none;
  padding: 1.5rem 0 0;

  &__item {
    padding-right: 1.75rem;
    white-space: nowrap;

    &_link {
      color: #78858b;
      text-decoration: none;
      font-weight: 500;

      &:hover,
      &.active {
        background: transparent;
        color: var(--text-default-color);
        border-bottom: 3px solid var(--text-default-color);
        padding-bottom: 1rem;
        border-radius: 0;

        path {
          fill: var(--text-default-color);
        }
      }
    }
  }
}

.button__container {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}
