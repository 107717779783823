.add__time__form {
  margin-top: 1rem;

  &__title {
    font-weight: 500;
  }

  &__time {
    display: flex;
    margin-bottom: 1.5rem;
    grid-gap: 1rem;

    &__value {
      flex-grow: 1 !important;
      max-width: 206px !important;
    }

    &__format {
      flex-grow: 1 !important;
      max-width: 206px !important;
    }
  }

  &__date {
    width: 100% !important;
    margin-bottom: 1.5rem !important;
  }

  &__comment {
    margin-bottom: 3rem !important;
    width: 100% !important;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;

    &__cancel {
      color: var(--cancel-color) !important;
      margin-right: 0.5rem !important;
    }

    &__cancel:hover {
      color: white !important;
    }
  }
}
