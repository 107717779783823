@import '../../scss/mixins';

.mainInfoBackground {
  background: url(../../assets/img/specialist/gradient.png) no-repeat;
  background-size: cover;
  padding: 120px 72px;
  @include for-tablet {
    padding: 70px 36px 126px 36px;
    background: linear-gradient(
      64.43deg,
      #f0f4f5 0%,
      #f3f2f0 46.5%,
      #f3f3f0 46.51%,
      #f7f0ea 99.19%
    );
  }
  @include for-mobile {
    padding: 24px 15px 32px 15px;
  }
}

.hideBr {
  @include for-mobile {
    display: none;
  }
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.mainInfoBlock {
  display: flex;
  justify-content: space-between;
}

.iconMain_popular {
  height: 550px;
  @include for-tablet {
    display: none;
  }
  @include for-mobile {
    display: none;
  }
}

.gapBenefits {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3.5rem;
  @include for-mobile {
    gap: 14px;
    margin-bottom: 28px;
  }
}

.mainPopular {
  background: var(--bg-light-grayish);
  padding-bottom: 100px;
}

.mainContainerPopular {
  max-width: 1440px;
  margin: 0 auto;
}

.mainBlock {
  padding: 68px 72px 0 72px;
  @include for-tablet {
    padding: 47px 36px;
  }
  @include for-mobile {
    padding: 35px 15px 0 15px;
  }
}

.cardTabs {
  @include for-tablet {
    display: none;
  }
  @include for-mobile {
    display: none;
  }
}

.cardTabsAdaptive {
  display: none;
  @include for-tablet {
    padding: 32px 25.5px;
    background: var(--bs-white);
    display: block;
  }
  @include for-mobile {
    display: block;
    padding: 10px 14px 16px 14px;
  }
}

.popularProjects {
  margin-bottom: 48px;
  @include for-tablet {
    margin-bottom: 24px;
  }
  @include for-mobile {
    margin-bottom: 24px;
    font-size: 26px;
    font-weight: 700;
  }
}

.statistics {
  display: flex;
  margin-bottom: 48px;
  @include for-mobile {
    margin-bottom: 42px;
  }
}
.infoTitleStatistics {
  color: #717171;
  font-size: 16px;
  font-weight: 400;
}
.infoStatistics {
  color: #1d1d1d;
  font-size: 36px;
  font-weight: 700;
  @include for-mobile {
    font-size: 22px;
  }
}

.line {
  width: 1px;
  background: #d9d9d9;
  margin-left: 48px;
  margin-right: 48px;
  border-radius: 3px;
  height: 100%;
  @include for-mobile {
    margin-left: 14px;
    margin-right: 14px;
  }
}

.searchButton {
  padding: 16px 30px !important;
  background: #199b3e !important;
  color: #fff !important;
  &:hover {
    background: #21b84b !important;
  }
  @include for-mobile {
    display: none !important;
  }
}

.searchBlock {
  margin-bottom: 43px;
}

.aboutButton {
  padding: 16px 18px !important;
  border: 1px solid #199b3e !important;
}

.title {
  font-weight: 500;
  font-size: 38px;
  font-family: Roboto, sans-serif;
  margin-bottom: 24px;
  line-height: 49.4px;
  @include for-tablet {
    font-size: 36px;
    line-height: 45px;
  }
  @include for-mobile {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 30px;
  }
  &__color {
    color: var(--text-default-color);
  }
}

.card_connectionElement {
  display: none;
  @include for-mobile {
    display: block;
    width: 2px;
    background: var(--buttonLightTheme);
    height: 26px;
    margin-left: 22px;
    border-radius: 6px;
  }
}

.mainRoles {
  background: var(--bg-light-grayish);
  padding: 68px 72px 80px;
  @include for-tablet {
    padding: 68px 36px;
  }
  @include for-mobile {
    padding: 32px 15px 43px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    @include for-tablet {
      flex-direction: column;
    }
    &__card {
      width: 33%;
      @include for-tablet {
        width: 100%;
      }
      background: #fff;
      display: flex;
      flex-direction: column;
      padding: 24px 28px 24px 23px;
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      &_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        margin-bottom: 20px;
        @include for-mobile {
          flex-direction: column;
        }
        img {
          width: 75px;
          height: 75px;
        }
        h4 {
          font-size: 24px;
          font-weight: 600;
          line-height: 28.13px;
          font-family: Roboto, sans-serif;
        }
      }
      &_block {
        display: flex;
        border-top: 1px solid #dcdcdc;
        padding-top: 14px;
        flex-direction: column;
        gap: 28px;
        &_btn {
          @include for-mobile {
            width: 100%;
          }
        }
      }
    }
  }
}

.form {
  background: var(--bg-light-grayish);

  .formMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 116px;
    @include for-tablet {
      padding: 0 36px 100px;
    }
    @include for-mobile {
      padding: 0 15px 100px;
    }
  }
}
