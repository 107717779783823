@import 'src/scss/mixins';
.yandexCard {
  display: flex;
  justify-content: space-between;
  background: var(--bg-light);
  box-shadow: 5px 5px 30px 0 rgba(111, 111, 111, 0.16);
  border-radius: 12px;
  @include for-tablet {
    flex-direction: column;
  }
  @include for-mobile {
    flex-direction: column;
  }
}

.cardLeft {
  padding: 32px 36px;
  @include for-mobile {
    padding: 16px;
  }
}

.description,
.title,
.titleService {
  color: var(--count-light);
  line-height: 24px;
}

.title {
  font-weight: 500;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  margin-bottom: 12px;
  line-height: 30px;
}

.description {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
}

.titleService {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 14px;
}

.yandexPicture {
  @include for-tablet {
    width: 100%;
    height: 100%;
  }
  @include for-mobile {
    display: none;
  }
}
