@import 'src/scss/mixins';

.about__me__info {
  margin-right: 2.5rem;
  margin-top: 2rem;

  @include for-tablet {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  @include for-mobile {
    margin-left: 0;
  }
}

.about__me__info__title {
  font-weight: 700;
  margin-bottom: 1.125rem;

  @include for-mobile {
    margin-bottom: 1rem;
  }
}

.about__me__info__content {
  font-weight: 500;
}

.resume {
  color: var(--bg-green);
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  max-width: max-content;
}

.certificate {
  color: var(--bg-green);
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  max-width: max-content;
}
