.block_photo {
  position: relative;
}

.block_sign {
  position: absolute;
  left: 72px;
  top: 32px;
}

.sidebar_profile_avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 2px solid #a7a7a7;
}

.sidebar_footer {
  margin-left: 51px;
  margin-bottom: 51px;
}

.main_photo {
  height: 100vh;
  object-fit: cover;
  object-position: center;
}
/* prevent markdown editor's toolbar overlap with header */
.g-md-editor-sticky_sticky-active {
  z-index: 1000 !important;
}
