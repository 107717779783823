.ownMessage {
  border-radius: var(--bs-border-radius);
  padding: 0.5rem;
  background-color: #d7f9e2;
}

.foreignMessage {
  border-radius: var(--bs-border-radius);
  padding: 0.5rem;
  background-color: white;
}
.messageBox {
  max-width: 50%;
  display: flex;
  padding: 12px 12px 6px 12px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 3px;
  border-radius: 12px 8px 8px 12px;
  word-wrap: break-word;
}
.ownMessageBox {
  background-color: #d7f9e2;
}
.foreignMessageBox {
  background-color: #ffffff;
}
.ownWithAvatar {
  margin-right: 0px;
}

.ownWithoutAvatar {
  margin-right: 72px;
}

.foreignWithAvatar {
  margin-left: 0px;
}

.foreignWithoutAvatar {
  margin-left: 72px;
}

.fullWidthMessage {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  max-width: 100%;
}

.smallWidthMessage {
  display: flex;
  flex-direction: row;
}

.messageTools {
  margin: 0 0.5rem;
  display: flex;
  align-items: center;
}

.fullWidthMessageTools {
  justify-content: flex-end;
}

.ownAvatar {
  margin-left: 12px;
  margin-right: 16px;
  width: 44px;
  height: 44px;
}

.foreignAvatar {
  margin-left: 16px;
  margin-right: 12px;
  width: 44px;
  height: 44px;
}

.messageDate {
  color: #78858b;
}

.star {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
}

.favorite__message__background {
  animation-duration: 3s;
  animation-name: green__background;
}

@keyframes green__background {
  0% {
    background: var(--bg-green);
  }
  100% {
    background: #f9f9f9;
  }
}
