.avatarCardMechanism {
  width: 75px;
  height: 75px;
  border: 2px solid #199b3e;
  border-radius: 50%;
}

.avatar {
  width: 100%;
  height: 100%;
}
