.container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid var(--border-gray);
  margin-bottom: 24px;
  &__links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    a {
      padding: 0;
      display: flex;
      flex-direction: row;
      gap: 6px;
      align-items: center;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      color: var(--cancel-color);
      position: relative;
      &.active {
        color: var(--text-default-color);
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background: var(--text-default-color);
          left: 0;
          bottom: -13px;
        }
        svg path {
          fill: var(--text-default-color);
        }
      }
    }
  }
}

.widerSvg {
  stroke-width: 2px;
}

.noProjects {
  margin: 0;
  color: var(--count-light);
  font-size: 26px;
  line-height: 31px;
}
