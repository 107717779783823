.modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.container {
  max-width: 480px !important;
  width: 100% !important;
}

.title {
  margin-bottom: 1rem;
}

.filter__input {
  margin-bottom: 1rem;
}

.projects__list {
  padding-left: 0;
}

.project__item {
  padding: 0.75rem 0;
  font-size: 16px;
  a {
    color: var(--bg-green);
  }
}

.divider {
  background-color: var(--buttonLightTheme) !important;
}
