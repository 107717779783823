.filter__wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  .search__by {
    font-size: 12px;
    color: var(--cancel-color);
    margin-bottom: 2px;
  }

  .filter__choosing {
    font-weight: 500;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__title {
      margin-right: 0.25rem;
    }
  }

  .search__input {
    max-width: 300px;
  }
}
