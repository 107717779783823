.lead__time {
  &__title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 18px;
  }

  &__dates {
    color: var(--count-light);
    font-size: 16px;
    line-height: 24px;
    font-family: Roboto, sans-serif;
    margin-bottom: 12px;
  }

  &__tasks__count {
    font-size: 14px;
    color: var(--cancel-color);
    font-weight: 500;
    margin-bottom: 0.75rem;
  }

  &__footer {
    font-size: 14px;
    color: var(--cancel-color);
    font-weight: 500;
    display: flex;
    justify-content: center;
  }

  &__list {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;

    &__color {
      background-color: var(--charts-green);
      width: 1rem;
      height: 1rem;
      border-radius: 4px;
    }

    &__text {
      font-weight: 500;
    }
  }
}

.emptyData {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 64px 0 16px;
  & p {
    color: var(--count-dark);
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
}
