.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 16px;
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    &_mark {
      width: 16px;
      height: 16px;
      border-radius: 4px;
    }
    &_label {
      color: var(--text-hover-color);
      font-size: 14px;
      line-height: 19.6px;
      font-family: Roboto, sans-serif;
    }
  }
}
