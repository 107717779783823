@import 'src/scss/mixins';

.name__field {
  width: 100%;
}

.description__field {
  width: 100%;
  textarea {
    min-height: 200px;
  }
}

.time_input {
  padding: 0;
  textarea {
    height: 18px !important;
  }
}

.form__container {
  display: flex;
  flex-direction: row;

  @include for-tablet {
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-right: 2rem;

    h2 {
      font-size: 26px;
    }

    @include for-tablet {
      margin-bottom: 2rem;
      margin-right: 0;
    }
  }

  &__fields {
    width: 45%;

    @include for-tablet {
      width: 80%;
    }

    @include for-mobile {
      width: 90%;
    }
  }
}

.duration__input {
  width: 100%;
}

.markdown {
  width: 100%;
}
