.container {
  display: flex;
  flex-direction: column;

  &__credentials {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 0 32px 16px;
    > div {
      display: flex;
      flex-direction: column;
      gap: 2px;
      > p {
        margin: 0;
        &:first-of-type {
          color: var(--count-dark);
        }
        &:last-of-type {
          color: var(--count-light);
          font-weight: 500;
        }
      }
    }
  }

  &__form {
    padding: 16px 32px 0;
    border-top: 1px solid var(--border-gray);
  }

  &__converstaion {
    margin-top: 24px;
  }
}

.wrapper {
  padding: 24px 0;
}

.title {
  padding: 0 32px;
}
