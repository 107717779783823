@import 'src/scss/mixins';

.searchBlock {
  display: flex;
  align-items: center;
  gap: 36px;
}

.search {
  background: var(--bg-light-gray);
  padding: 32px 72px 32px 72px;
  @include for-tablet {
    padding: 26px 32px 26px 36px;
    margin: 0;
  }
  @include for-mobile {
    display: flex;
    align-items: center;
    padding: 20px 15px;
  }
}

.blockTuneIcon {
  display: none;
  @include for-tablet {
    display: block;
  }
  @include for-mobile {
    display: block;
  }
}

.header {
  display: flex;
  gap: 24px;
  padding-left: 0;
  flex-wrap: wrap;
  border-bottom: var(--buttonLightTheme) 1px solid;
  a {
    display: inline-block;
    color: var(--itemSidebarColor-light);
    padding-bottom: 12px;
    &:hover {
      color: var(--bg-green);
    }
    font-weight: 500;
  }
  :global .active {
    color: var(--bg-green);
    border-bottom: var(--text-light-green) 1px solid;
    margin-bottom: -1px;
    &:hover {
      color: var(--text-light-green);
    }
  }
}
.mainContainer {
  max-width: 1440px;
  margin: 0 auto;
}

.containerSearch {
  max-width: 1440px;
  margin: 0 auto;
  @include for-tablet {
    margin: 0;
  }
}

.container {
  margin: 24px 72px 28px 72px;
  @include for-tablet {
    margin: 16px 36px 18px 36px;
  }
  @include for-mobile {
    margin: 16px 15px;
  }
}

.containerAlert {
  margin: 24px 72px 28px 72px;
  @include for-tablet {
    margin: 16px 36px 18px 36px;
  }
  @include for-mobile {
    margin: 0;
  }
}

.containerHeader {
  @include for-mobile {
    margin: 16px 15px;
  }
}

.main {
  display: grid;
  grid-template-columns: 1fr 6fr;
  gap: 1.5rem;
  //margin: 28px 72px 98px 72px;
  @include for-tablet {
    display: flex;
    gap: 0;
  }
}

.jobBox {
  @include for-tablet {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.settingSearch {
  @include for-tablet {
    display: none;
  }
}

.hideFindBtn {
  @include for-tablet {
    display: none !important;
  }
  @include for-mobile {
    display: none !important;
  }
}

.filterSearch {
  margin: 24px 51px;
  @include for-mobile {
    margin: 20px 15px;
  }
}

.filterTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  margin: 0;
}

.filterBlock {
  margin-bottom: 20px;
}

.filterBtn {
  padding: 14px 84.5px !important;
  @include for-mobile {
    width: 100%;
  }
}
