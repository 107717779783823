.card {
  padding: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.125rem;
  background: var(--bs-white);
  border-radius: 4px;
  box-shadow: 2px 4px 12px 0 rgba(91, 91, 91, 0.06);
}

.cardDescription {
  font-family: Roboto, sans-serif;
  color: var(--count-light);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}
