.form {
  display: flex;
  flex-direction: column;
  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    &_cancel {
      color: var(--cancel-color) !important;
      &:hover {
        color: var(--cancel-color) !important;
        background-color: transparent !important;
      }
    }
  }
}
