.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  > div {
    background-color: #f9fafc;
    border: 1px solid var(--border-gray);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 11px 14px;
    min-width: 135px;
    p {
      margin: 0;
      &:first-of-type {
        color: var(--cancel-color);
        font-size: 12px;
        line-height: 16px;
      }
      &:last-of-type {
        color: var(--count-light);
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
