.pagination_container {
  --bs-pagination-color: #78858b;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 0;
  --bs-pagination-hover-color: #78858b;
  --bs-pagination-hover-bg: #f9fafc;
  --bs-pagination-hover-border: none;
  --bs-pagination-hover-border-color: none;
  --bs-pagination-focus-color: #78858b;
  --bs-pagination-focus-bg: #f5f5f5;
  --bs-pagination-active-color: #78858b;
  --bs-pagination-active-bg: none;
  --bs-pagination-active-border-color: #4af3e2;
  --bs-pagination-disabled-color: #dcdcdc;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: none;
}

.button {
  margin-right: 5px;
  outline: none;
  border: 1px solid transparent;

  &__active {
    background: none !important;
    border: 1px solid #4af3e2;
    border-radius: 8px;
    color: #78858b;
  }
}
