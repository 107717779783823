@import '../../scss/mixins';

.mainInfoBackground {
  background: url(../../assets/img/customer/background.png);
  background-size: cover;
  padding-left: 72px;
  padding-right: 72px;
  @include for-tablet {
    padding: 138px 36px 233px 36px;
  }
  @include for-mobile {
    padding: 64px 15px 126px 15px;
  }
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.mainInfoBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mainInfoSign {
}

.iconCustomer {
  height: 629px;
  @include for-tablet {
    display: none;
  }
  @include for-mobile {
    display: none;
  }
}

.wrapperCardsCustomer {
  background: var(--bg-light);
  padding: 27px 41px 53px 40px;
  @include for-tablet {
    display: none;
  }
}

.card_connectionElement {
  display: none;
  @include for-mobile {
    display: block;
    width: 2px;
    background: var(--buttonLightTheme);
    height: 26px;
    margin-left: 22px;
    border-radius: 6px;
  }
}

.tabletCardsCustomer {
  display: none;
  @include for-tablet {
    padding: 32px 25.5px;
    background: var(--bs-white);
    display: block;
  }
  @include for-mobile {
    display: block;
    padding: 10px 14px 16px 14px;
  }
}

.title {
  font-size: 32px;
  font-weight: 500;
  line-height: 46.6px;
  margin: 0;
  color: var(--count-light);
  span {
    color: var(--text-default-color);
  }
  @include for-tablet {
    font-size: 24px;
    line-height: 28.8px;
  }
  @include for-mobile {
    font-size: 22px;
    line-height: 26.4px;
  }
}

.mainInfoBtn {
  margin-top: 3.5rem;
}

.btn {
  padding: 14px 24px !important;
}

.mainPopular {
  padding: 80px 72px 103px 72px;
  background: var(--bg-light-grayish);
  @include for-tablet {
    padding: 48px 36px 64px 36px;
  }
  @include for-mobile {
    padding: 35px 15px 48px 15px;
  }
}

.about_gridLayout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  @include for-tablet {
    grid-template-columns: 1fr 1fr;
  }
  @include for-mobile {
    grid-template-columns: 1fr;
  }
}

.aboutControl {
  background: var(--bg-light);
  padding: 56px 72px 74px 72px;
  @include for-tablet {
    padding: 24px 36px 54px 36px;
  }
  @include for-mobile {
    padding: 36px;
  }
}

.about_block {
  display: flex;
  justify-content: space-between;
  gap: 48px;
  @include for-tablet {
    flex-direction: column;
  }
}

.about_common {
  display: flex;
  flex-direction: column;
  gap: 18px;
  @include for-tablet {
    margin-bottom: 32px;
  }
}

.about_title,
.about_description {
  font-family: Roboto, sans-serif;
}

.about_title {
  color: var(--text-default-color);
  font-weight: 500;
  font-size: 38px;
  line-height: 45px;

  @include for-tablet {
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
  }
  @include for-mobile {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
  }
}

.hideBr {
  @include for-tablet {
    display: none;
  }
}

.about_description {
  font-weight: 500;
  font-size: 18px;
  @include for-mobile {
    font-size: 16px;
  }
}

.about_icon {
  height: 421px;
  @include for-tablet {
    height: 100%;
    width: 100%;
  }
  @include for-mobile {
    width: 100%;
    height: 100%;
  }
}

.title_popular {
  font-size: 38px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  line-height: 45px;
  color: var(--count-light);
  margin-bottom: 43px;
  @include for-tablet {
    margin-bottom: 24px;
  }
}

.title_benefits {
  margin-bottom: 46px;
  font-weight: 500;
  font-size: 38px;
  font-family: Roboto, sans-serif;
  line-height: 45px;
  color: var(--count-light);
  @include for-tablet {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 31px;
  }
  @include for-mobile {
    font-size: 26px;
    line-height: 31px;
    margin-bottom: 18px;
  }
}
