.container {
  display: flex;
  flex-direction: column;

  > p {
    color: var(--count-light);
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 32px;

      &_cancel {
        color: var(--cancel-color) !important;

        &:hover {
          color: var(--cancel-color) !important;
          background-color: transparent !important;
        }
      }
    }
  }
}

.hidden {
  display: none !important;
}
