.menu {
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    border-bottom: 1px solid var(--border-gray);
  }

  & > *:hover {
    background-color: #199b3e0d;
  }
}
