@import 'src/scss/mixins';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 208px;
  padding: 86px 0 125px;
  @include for-tablet {
    padding: 92px 0 197px;
    gap: 56px;
    flex-wrap: wrap;
  }
  @media (max-width: 690px) {
    gap: 45px;
    padding: 40px 0;
  }
  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    @include for-tablet {
      gap: 32px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media (max-width: 690px) {
        align-items: center;
      }
      & h1 {
        color: var(--bg-green);
        font-size: 160px;
        line-height: 187px;
        font-weight: normal;
        font-family: Roboto, sans-serif;
        margin: 0;
        @include for-tablet {
          font-size: 139px;
          line-height: 162.89px;
        }
      }
      & h3 {
        color: var(--count-light);
        line-height: 27px;
        font-size: 20px;
        font-weight: 500;
        margin: 0;
        @include for-mobile {
          font-size: 16px;
          line-height: 22.4px;
        }
      }
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      gap: 24px;
      align-items: center;
      &_back {
        & span {
          color: var(--cancel-color);
          line-height: 18px;
          font-size: 14px;
          font-weight: 500;
          font-family: Roboto, sans-serif;
        }
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
      }
    }
  }
  &__img {
    width: 380px;
    height: 409px;
    @include for-tablet {
      width: 294px;
      height: 316px;
    }
  }
}
