.wrapper {
  margin: 0 1.5rem 1.5rem 0;
}

.search__message {
  margin: 1.5rem 0 1rem 1.5rem !important;
  width: 365px !important;
}

.message__list {
  padding: 0;
  margin-left: 1.5rem;
}
