.container {
  display: flex;
  flex-direction: column;
  &__info {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 12px;
    > img {
      width: 40px;
      height: 40px;
    }
    > div {
      display: flex;
      flex-direction: column;
      .name {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        > p {
          margin: 0;
          &:first-of-type {
            color: var(--count-light);
            font-weight: 500;
          }
          &:last-of-type {
            color: var(--cancel-color);
            font-size: 14px;
          }
        }
      }
    }
  }
  > p {
    margin: 0 0 18px;
    color: var(--count-light);
  }
}
