@import 'src/scss/mixins';

.project__tasks {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-top: 6px;
    margin-right: 1.5rem;

    &__title {
      h2 {
        font-size: 26px;
        color: var(--count-light);
        font-weight: 700;
      }
    }

    &__buttons {
      display: flex;
      height: 38px;
      gap: 2rem;

      @include for-mobile {
        gap: 0.5rem;
      }
    }

    @include for-tablet {
      margin-right: 0;
    }
  }
}
