.block {
  display: flex;
  align-items: center;
  gap: 5.5px;
  margin-bottom: 18px;
}

.block__title {
  font-size: 24px;
  margin: 0;
  font-weight: 600;
  line-height: 28.13px;
  font-family: Roboto, sans-serif;
  color: var(--count-light);
}

.subBlock {
  margin-bottom: 12px;
}

.periodBlock {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 12px;
}

.subtitle {
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  line-height: 18px;
  color: var(--cancel-color);
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status__color {
  width: 1rem;
  height: 1rem;
  margin-right: 6px;
  border-radius: 4px;
  background: #f3cd47;
}

.period {
  color: var(--count-light);
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto, sans-serif;
  margin-bottom: 12px;
}

.emptyData {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 64px 0 16px;
  & p {
    color: var(--count-dark);
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
}
