@import 'src/scss/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 24px;

  @include for-tablet {
    margin: 0;
  }
}

.nav__back {
  margin-top: 1.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  background-color: white;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  padding: 2rem;
  margin-bottom: 69px;

  p {
    margin: 0;
  }

  @include for-mobile {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.edit__button {
  height: 2.5rem !important;
}

.settings__button {
  margin-left: 1.5rem !important;
}

.title_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding-bottom: 8px;
}

.title {
  display: flex;
  gap: 16px;
  align-items: center;

  h1 {
    font-size: 26px;
    font-weight: 700;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 6px;
}

.item {
  display: flex;
  flex-direction: column;
  max-width: 586px;
  margin-right: 2rem;

  b {
    font-weight: 400;
    color: #a7a7a7;
  }
}

.bold__item {
  font-weight: 500;
}

.toggle {
  display: flex;
  align-items: center;
  gap: 16px;
}

.divider {
  border-bottom: #f0f0f0 1px solid;
  margin: 6px 0;
}

.attachments__button {
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
}

.navBack {
  max-width: fit-content;
}
