.chatActive {
  display: block;
  background-color: #f9f9f9;
  border-left: solid 4px #199b3e;
  color: #39393a;
}

.chatActive:hover {
  color: #39393a;
}

.chatLink {
  color: #39393a;
}

.chatLink:hover {
  color: #39393a;
}

.chat {
  flex-wrap: wrap;
  width: 100%;
}

.avatar {
  height: 2.75rem;
  width: 2.75rem;
}

.chatHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.chatBody {
  margin-left: 8px;
  width: 80%;
}

.messageBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.unreadMessagesCount {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #e04040;
  border-radius: 50%;
  color: #fafafa;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 100%;
  min-width: 1.4rem;
  padding: 0.1875rem 0.3125rem;
}
.messageOptions {
  display: flex;
  justify-content: flex-end;
}
