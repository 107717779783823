@import 'src/scss/mixins';

.form {
  @include for-mobile {
    border: none;
    min-width: 100%;
    padding: 24px 15px;
    margin-top: 0 !important;
  }
}

.btnBlock {
  margin-top: 56px;
}

.form_success {
  font-size: 16px;
  font-weight: 500;
  @include for-mobile {
    border: 1px solid #e4e4e4;
    padding: 32px;
  }
}
