.yAxisLabel {
  position: relative;
  & p {
    color: var(--cancel-color);
    font-size: 14px;
    font-family: Roboto, sans-serif;
    line-height: 18px;
    font-weight: 500;
    position: absolute;
  }
}
