.modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.container {
  max-width: 480px !important;
  width: 100% !important;
}

.title {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.buttons {
  margin-top: 3.5rem;
  display: flex;
  justify-content: flex-end;

  .button__cancel {
    color: var(--cancel-color);
    :hover {
      color: #fff;
    }
  }

  .button__send {
    margin-left: 0.5rem;
  }
}
