.edit__template {
  &__card {
    display: flex;
    padding: 2.5rem 2rem 3rem;
    margin-right: 1.5rem;
    gap: 3rem;

    &__title {
      flex: 1;

      h2 {
        font-family: Roboto;
        font-size: 26px;
        font-weight: 700;
        line-height: 31.2px;
        text-align: left;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}
