@import '../../../scss/mixins';

.container {
  padding: 43px 72px 144px 72px;
  min-height: calc(100vh - 350px);

  @include for-tablet {
    padding: 35px 36px;
  }

  @include for-mobile {
    padding: 26px 15px 80px 15px;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.subContainer {
  margin: 0 auto;
  max-width: 1440px;
}
