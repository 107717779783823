.container {
  padding: 1.5rem 2rem 2rem 2rem !important;
}

.statuses {
  margin-top: 2rem;
}

.title {
  margin-bottom: 2rem;
}

.content {
  display: flex;
}
