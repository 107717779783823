.noNotifications {
  font-weight: 500;
  margin: 0;
  font-size: 14px;
}
.link {
  margin-top: 10px;
  color: #199b3e;
  line-height: 18px;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  &:hover {
    color: #21b84b;
  }
  &:focus {
    color: #148c36;
  }
}
.notifications_container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .item {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    border-bottom: 1px solid #dcdcdc;
  }
}
