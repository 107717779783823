.container {
  max-width: 1480px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  min-height: calc(100vh - 400px);
}

.header {
  padding-top: 35px;
  padding-bottom: 27px;
}

.wrapper {
  margin-bottom: 64px;

  & > * {
    color: #39393a;
  }

  & h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 122%;
    margin-bottom: 0;
  }

  & h2 {
    font-weight: 500;
    font-size: 26px;
    line-height: 119%;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
}

.pagination {
  padding-top: 1rem;
  margin-bottom: 120px;
}

@media (max-width: 768px) {
  .header {
    padding-bottom: 18px;

    & h1 {
      font-size: 26px;
    }
  }
  .wrapper {
    & h2 {
      font-size: 20px;
      margin-bottom: 0.25rem;
    }
  }
}
