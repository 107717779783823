.actions__modal__wrapper {
  display: flex;
  justify-content: center;
}

.actions__modal {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: var(--bg-light);
  border-radius: 4px;
  position: relative;
  max-width: 290px;
  text-align: center;
}
