.container {
  display: flex;
  flex-direction: column;
}
.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;
  &_item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    & p {
      margin: 0;
      color: var(--count-light);
      font-family: Roboto, sans-serif;
      font-weight: 500;
      line-height: 22.4px;
      font-size: 16px;
    }
  }
}
.charts_container {
  display: flex;
  flex-direction: column;
  gap: 56px;
}
