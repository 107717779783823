.template__form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex-grow: 3;

  .input__field {
    max-width: 416px;

    @media (max-width: 520px) {
      max-width: 320px;
    }

    @media (max-width: 400px) {
      max-width: 250px;
    }
  }
}
