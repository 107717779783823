.container {
  background-color: #fff;
  padding: 32px;
  height: 93%;
  margin-top: 20px;
  margin-right: 1.5rem;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  overflow-y: hidden;
  .header {
    margin: 0 0 16px;
    line-height: 140%;
    font-size: 26px;
    font-weight: 700;
    color: #39393a;
    font-family: Roboto;
  }
  .notifications {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 657px;
    .item {
      padding: 14px 0;
      &:not(:last-child) {
        border-bottom: 1px solid #dcdcdc;
      }
    }
  }
}
