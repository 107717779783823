.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  h2 {
    margin: 0;
    color: var(--count-light);
    font-size: 26px;
    line-height: 31px;
    font-weight: 500;
  }
}

.manager {
  display: flex;
  flex-direction: column;
  gap: 6px;
  > p {
    margin: 0;
    color: var(--count-dark);
    line-height: 22px;
  }

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      margin: 0;
      color: var(--count-light);
      line-height: 22px;
    }
  }
}
