@import 'src/scss/mixins';

.container {
  padding: 40px 73px 136px 72px;
  @include for-tablet {
    padding: 48px 36px;
  }
  @include for-mobile {
    padding: 32px 15px;
  }
}

.title {
  color: #000;
  font-family: Roboto, sans-serif;
  font-style: normal;
  line-height: 140%;
  margin-bottom: 24px;
}

.info {
  display: flex;
  flex-direction: column;
  margin: 12px 0 24px 0;
  p {
    margin: 0;
  }
}

.subTitle {
  margin: 0;
}
