@import '../../scss/mixins';

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.mainInfoBackground {
  background: url(../../assets/img/specialist/gradient.png);
  background-size: cover;
  padding: 24px 72px;
  @include for-tablet {
    padding: 36px;
    background: linear-gradient(
      64.43deg,
      #f0f4f5 0%,
      #f3f2f0 46.5%,
      #f3f3f0 46.51%,
      #f7f0ea 99.19%
    );
  }
  @include for-mobile {
    padding: 15px;
  }
}

.mainInfoBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 629px;
  @include for-tablet {
    height: 502px;
  }
  @include for-mobile {
    height: 360px;
  }
}

.title,
.subTitle {
  font-family: Roboto, sans-serif;
  margin: 0;
}

.title {
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 18px;
  line-height: 45px;
  color: var(--count-light);
  span {
    color: var(--text-hover-color);
  }
  @include for-tablet {
    font-size: 36px;
    line-height: 46.8px;
    margin-bottom: 12px;
  }
  @include for-mobile {
    font-size: 26px;
    margin-bottom: 16px;
  }
}

.subTitle {
  color: var(--color-gray);
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  margin-bottom: 26px;
  @include for-tablet {
    font-size: 16px;
    margin-bottom: 18px;
  }
  @include for-mobile {
    font-size: 16px;
    margin-bottom: 16px;
  }
  span {
    color: var(--text-hover-color);
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.specialistsPic {
  height: 500px;
  @include for-tablet {
    display: none;
  }
  @include for-mobile {
    display: none;
  }
}

.title_rates {
  margin: 64px 0 0 0;
  @include for-tablet {
    margin: 48px 0 0 0;
  }
  @include for-mobile {
    margin: 35px 0 0 0;
  }
}

.addGoodsWrapper {
  padding: 0 72px 0 72px;
  @include for-tablet {
    padding: 0 36px 0 36px;
  }
  @include for-mobile {
    padding: 0 15px 0 15px;
  }
}

.wrapper {
  margin-bottom: 118px;
  @include for-tablet {
    margin-bottom: 119px;
  }
  @include for-mobile {
    margin-bottom: 95px;
  }
}

.gridLayout {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;

  @include for-tablet {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
  @include for-mobile {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}

.options {
  margin: 56px 0 40px 0;
  @include for-tablet {
    margin: 48px 0 24px 0;
  }
  @include for-mobile {
    margin: 48px 0 28px 0;
  }
}
