@import 'src/scss/mixins';

.common__analytics {
  margin-bottom: 4rem;
  display: flex;
  flex-direction: row;

  @include for-tablet {
    flex-direction: column;
  }
}
