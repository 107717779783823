.tags__list {
  margin-right: 1rem;

  &__tag {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    &__color {
      width: 1rem;
      height: 1rem;
      margin-right: 6px;
      border-radius: 4px;
    }

    &__title {
      color: var(--cancel-color);
      margin-right: 0.25rem;
      font-weight: 500;
    }

    &__count {
      font-weight: 700;
    }
  }
}
