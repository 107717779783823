@import 'src/scss/mixins';

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 43px 0 144px 0;
  min-height: calc(100vh - 350px);
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.text {
  p,
  span {
    font-family: Roboto, sans-serif !important;
  }
}

.docs {
  padding-left: 72px;
  padding-right: 72px;

  @include for-tablet {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include for-mobile {
    padding-left: 16px;
    padding-right: 16px;
  }
}
