@import 'src/scss/mixins';

.container {
  margin: 0 auto;
  max-width: 1440px;
}

.aboutSetUpProfileMain {
  display: flex;
  flex-direction: column;
  gap: 100px;

  @include for-mobile {
    gap: 48px;
  }
}

.aboutSetUpProfile {
  display: flex;
  gap: 40px;
  @include for-tablet {
    flex-direction: column;
    gap: 32px;
  }
  @include for-mobile {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
}

.titleRecommendation {
  color: var(--count-light);
  font-weight: 600;
  font-size: 18px;
  font-family: Roboto, sans-serif;
  line-height: 23.4px;
}

.aboutSetUpProfileBlock {
  padding: 80px 72px;
  background: #fafafa;
  gap: 100px;
  @include for-tablet {
    padding: 48px 36px;
  }
  @include for-mobile {
    padding: 21px 15px 48px 15px;
  }
}

.titleBlock {
  display: flex;
  flex-direction: column;
  gap: 23px;
  @include for-mobile {
    gap: 0;
  }
}

.findVacancyList {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 23.4px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: var(--count-light);
}

.marker {
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 23.4px;
  color: var(--text-default-color);
}

.typographyBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include for-mobile {
    gap: 1rem;
    margin-bottom: 1.75rem;
  }
}

.image {
  max-width: 730px;
  @include for-tablet {
    width: 100%;
  }
  @include for-mobile {
    width: 100%;
  }
}

.aboutPopular {
  padding: 64px 72px 88px 72px;
  background: var(--bg-light);
  @include for-tablet {
    padding: 48px 36px 64px 36px;
  }
  @include for-mobile {
    padding: 35px 15px 48px 15px;
  }
}

.titleAboutBenefits {
  font-weight: 500;
  font-size: 38px;
  line-height: 45px;
  font-family: Roboto, sans-serif;
  color: var(--count-light);
  margin-bottom: 47px;
  @include for-tablet {
    margin-bottom: 28px;
  }
  @include for-mobile {
    font-size: 26px;
    line-height: 31px;
    margin-bottom: 24px;
  }
}

.aboutCardsLayout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 49px;
  @include for-tablet {
    grid-template-columns: 1fr;
    gap: 24px;
  }
  @include for-mobile {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.cardWithoutShadow {
  box-shadow: none !important;
  padding: 0 !important;
  justify-content: start !important;
}

.pricing {
  background-color: #fafafa;
  padding: 64px 72px 28px;
  @include for-tablet {
    padding: 56px 36px 16px;
  }
  @include for-mobile {
    padding: 40px 15px 20px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    @include for-mobile {
      gap: 16px;
    }
    &__description {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      p {
        font-family: Roboto, sans-serif;
        margin: 0;
      }
    }
  }
}

.documenation {
  background-color: #fafafa;
  padding: 28px 72px 144px;
  @include for-tablet {
    padding: 15px 36px 100px;
  }
  @include for-mobile {
    padding: 20px 15px 100px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    @include for-mobile {
      gap: 16px;
    }
    &__links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      a {
        font-family: Roboto, sans-serif;
      }
    }
  }
}
