@import 'src/scss/mixins';

.profile__content {
  border-radius: 4px;
  margin-top: 24px;
  margin-right: 1.5rem;
  margin-bottom: 3.5rem;
  padding-left: 2rem;
  --bs-border-width: 1px;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;

  @include for-tablet {
    border: none !important;
    background: var(--bg-default) !important;
    margin-top: 0;
    margin-right: 0;
    padding: 0;
  }
}
