.container {
  background: var(--bg-default);
  display: flex;
  height: 100vh;
  overflow-y: scroll;
}

.container.dark {
  background: var(--bg-black);
}
