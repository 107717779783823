@import 'src/scss/mixins';

.list__wrapper {
  .list__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    @include for-mobile {
      flex-direction: column-reverse;
    }

    .additional__element {
      @include for-mobile {
        margin-bottom: 0.5rem;
      }
    }
  }

  .list__content {
    padding: 18px 32px 24px 32px;

    ul {
      padding: 0;
    }
  }
}
