.icon {
  padding-right: 10px;
}

.itemSidebar {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin: 14px 18px 12px 10px;
  text-decoration: none;
  color: var(--itemSidebarColor-light);
  background: var(--bg-light);
  border-radius: 8px;
  white-space: nowrap;

  &:hover,
  &.active {
    color: var(--text-default-color);
    cursor: pointer;
    border-radius: 8px;
  }
  &:hover svg path {
    fill: var(--text-default-color);
  }
}

.itemSidebar.dark {
  background: var(--bg-dark);
  color: var(--itemSidebarColor-dark);
}

.activeBtn {
  background: var(--lightActiveBtn-light);
  border-radius: 8px;
  color: var(--text-default-color);
  & svg path {
    fill: var(--text-default-color);
  }
}

.activeBtn.dark {
  background: var(--darkAcitveBtn-dark);
  color: var(--text-hover-color);
}
