.header {
  width: auto;
  margin: 0 1.5rem 1.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(120, 133, 139, 0.3);
  min-height: 65px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;

    width: 100%;
    align-items: center;

    li {
      a {
        display: flex;
        align-items: center;
        font-weight: 500;
        padding: 27px 0 12px 0;

        text-decoration: none;
        color: #78858b;
        margin: 0 24px 0 0;
        border-bottom: 1px solid rgba(64, 224, 208, 0);
        transition: color 0.3s ease, border-bottom 0.3s ease;

        svg {
          path {
            fill: #78858b;
            transition: fill 0.4s ease;
          }
        }
      }

      :global a.active {
        color: var(--text-default-color);
        background: transparent;
        border-radius: 0;
        border-bottom: 1px solid var(--text-default-color);

        svg path {
          fill: var(--text-default-color);
        }
      }

      &:hover {
        a {
          color: var(--text-default-color);
          border-bottom: 1px solid var(--text-default-color);

          svg path {
            fill: var(--text-default-color);
          }
        }
      }
    }
  }

  button {
    color: #fff;
    background: #199b3e;
    font-weight: 600;
    display: flex;
    border-radius: 8px;
    border: 1.5px solid rgba($color: #000000, $alpha: 0);
    padding: 0.35% 1%;
    white-space: nowrap;
    align-items: center;
    height: fit-content;
    transition: all 0.6s ease;

    svg {
      width: 25px;
      height: 25px;
      margin-right: 4%;
      path {
        fill: #fff;
        transition: all 0.6s ease;
      }
    }

    &:hover {
      background: transparent;
      color: #21b84b;
      border: 1.5px solid rgba($color: #21b84b, $alpha: 1);

      svg path {
        fill: #21b84b;
      }
    }
  }
}
