.form {
  display: flex;
  flex-direction: row;
  &:nth-child(1) {
    width: 100%;
  }
}

.sendButton {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  path {
    fill: #199b3e;
  }
}
