.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .notification_text {
    margin: 0;
    color: #39393a;
    font-size: 14px;
    line-height: 18px;
    font-family: Roboto;
  }
  .notification_date {
    color: #78858b;
    font-size: 14px;
    line-height: 18px;
    font-family: Roboto;
    margin: 0;
  }
  .notification_link {
    color: #199b3e;
    font-size: 14px;
    line-height: 18px;
    &:hover {
      color: #21b84b;
    }
  }
}
