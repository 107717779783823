.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  > h3 {
    color: var(--count-light);
    margin: 0;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
  }
}

.manager {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
  > h6 {
    margin: 0;
    color: var(--count-dark);
    font-size: 16px;
    font-weight: 400;
  }
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    > p {
      color: var(--count-light);
      margin: 0;
    }
  }
}

.participants {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.table {
  width: fit-content;
  min-width: 740px;
}
