@import 'src/scss/mixins';

.card {
  width: 165px;
  height: 150px;
  border: solid 1px var(--border-gray);
  border-radius: 3px;
  padding: 0.75rem 0.5rem 0.75rem 1rem;

  &__icon {
    margin-bottom: 1rem;
  }

  &__text {
    font-size: 13px;
    color: var(--cancel-color);
    margin-bottom: 0.5rem;

    @include for-mobile {
      font-size: 12px;
    }
  }

  &__count {
    font-size: 42px;
    line-height: 44px;
    font-weight: 700;

    @include for-mobile {
      font-size: 26px;
      line-height: 20px;
    }
  }

  @include for-mobile {
    width: 150px;
    height: 136px;
  }
}
