.nav__back__button {
  align-items: center;
  padding: 10px 16px 12px 10px !important;

  &__title {
    height: 18px;
    margin-left: 2px;
    font-weight: 500;
  }
}
