@import 'src/scss/mixins';
.choiceCard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include for-tablet {
    grid-template-columns: 1fr;
  }
}

.title,
.description {
  margin: 0;
  color: var(--count-light);
}

.title {
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  font-family: Roboto, sans-serif;
  @include for-tablet {
    font-size: 32px;
  }
  @include for-mobile {
    font-size: 26px;
  }
}

.description {
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  @include for-mobile {
    font-size: 18px;
  }
}
