@import '../../scss/mixins';

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.mainInfoBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 629px;
  @include for-tablet {
    height: 502px;
  }
  @include for-mobile {
    height: auto;
  }
}

.mainInfoBackground {
  background: url(../../assets/img/specialist/gradient.png);
  background-size: cover;
  padding: 72px;
  @include for-tablet {
    padding: 36px;
    background: linear-gradient(
      64.43deg,
      #f0f4f5 0%,
      #f3f2f0 46.5%,
      #f3f3f0 46.51%,
      #f7f0ea 99.19%
    );
  }
  @include for-mobile {
    background: url(../../assets/img/specialist/gradient.png);
    background-size: cover;
  }
}

.specialistsPic {
  height: 500px;
  @include for-tablet {
    display: none;
  }
  @include for-mobile {
    display: none;
  }
}

.gridLayout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  @include for-tablet {
    grid-template-columns: 1fr;
  }
  @include for-mobile {
    grid-template-columns: 1fr;
  }
}

.blockGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @include for-tablet {
    grid-template-columns: repeat(2, 1fr);
  }
  @include for-mobile {
    grid-template-columns: repeat(1, 1fr);
  }
}

.gap1 {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  gap: 1.5rem;
  @include for-mobile {
    gap: 1rem;
    margin-top: 1rem;
  }
}

.search {
  background: var(--bg-light-gray);
  padding: 32px 72px 56px 72px;
  @include for-tablet {
    padding: 32px 36px 38px 36px;
  }
  @include for-mobile {
    padding: 15px;
  }
}

.searchTitle {
  margin-bottom: 35px;
  @include for-tablet {
    margin-bottom: 32px;
  }
}

.title,
.subTitle {
  font-family: Roboto, sans-serif;
  color: var(--count-light);
  margin: 0;
}

.title {
  font-size: 38px;
  font-weight: 500;
  line-height: 49.4px;
  @include for-tablet {
    font-size: 36px;
    line-height: 46.8px;
  }
  @include for-mobile {
    font-weight: 700;
    font-size: 22px;
    line-height: 26.4px;
  }
}

.subTitle {
  font-size: 32px;
  font-weight: 300;
  line-height: 41.6px;

  @include for-tablet {
    font-size: 34px;
    line-height: 44.2px;
  }
  @include for-mobile {
    font-size: 16px;
    line-height: 22.4px;
  }
  span {
    color: var(--text-default-color);
  }
}

.mainInfoBtn {
  margin-top: 3.5rem;
}

.btn {
  padding: 14px 24px !important;
}

.mainPopular {
  padding: 80px 72px;
  background: var(--bg-light-grayish);
  @include for-tablet {
    padding: 48px 36px;
  }
  @include for-mobile {
    padding: 32px 15px 58px 15px;
  }
}

.mainBenefits {
  padding: 28px 72px 100px 72px;
  background: var(--bg-light-grayish);
  @include for-tablet {
    padding: 0 36px 73px 36px;
  }
  @include for-mobile {
    padding: 32px 15px 58px 15px;
  }
}

.buttonBlock {
  margin-top: 48px;
  display: flex;
  justify-content: center;
}

.popularProjects {
  font-weight: 500;
  font-size: 38px;
  line-height: 45px;
  font-family: Roboto, sans-serif;
  color: var(--count-light);
  margin-bottom: 42px;
  @include for-mobile {
    margin-bottom: 16px;
    font-size: 26px;
    font-weight: 700;
  }
}
