@import 'src/scss/mixins';

.edit__forms__wrapper {
  margin-left: 24px;
  margin-top: 56px;
  padding-bottom: 1rem;
  padding-top: 1.5rem;

  @include for-tablet {
    margin-left: 1.5rem;
  }

  @media (min-width: 600px) {
    margin-top: 64px;
  }
}
