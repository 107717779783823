.container {
  padding: 1.5rem 2rem 0.5rem 2rem;
}

.title {
  margin-bottom: 1.5rem;
}

.question__icon {
  margin-left: 0.25rem !important;
}
