.rateYandexCard {
  background: var(--bg-light);
  box-shadow: 5px 5px 30px 0 rgba(111, 111, 111, 0.16);
  border-radius: 12px;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  height: 589px;
}

.profitable {
  border: 4px solid var(--text-default-color);
  box-shadow: 5px 5px 30px 0 rgba(25, 155, 62, 0.3);
}

.title {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: var(--count-light);
  margin-bottom: 4px;
}

.priceBlock {
  display: flex;
  align-items: flex-end;
  gap: 9px;
  margin-bottom: 2px;
  padding-bottom: 8px;
}

.price {
  font-weight: 600;
  font-size: 38px;
  line-height: 43.7px;
  color: var(--color-red);
  margin: 0;
}

.oldPrice {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: var(--gray-color);
  position: relative;
  margin: 0;
}

.oldPrice::after {
  content: '';
  width: 48px;
  height: 2px;
  background: var(--gray-color);
  position: absolute;
  top: 50%;
  right: 14px;
  transform: rotate(-30deg);
}

.timeOfUse {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--count-light);
  margin: 0;
}

.items {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  gap: 20px;
}

.btnBlock {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
