.favoriteMessage {
  display: flex;
  flex-direction: row;
  padding: 1.75rem 0;
  align-items: center;

  .author {
    flex-basis: 20%;
    display: flex;
    align-items: center;

    .authorAvatar {
      width: 2.75rem;
      height: 2.75rem;
      margin-right: 0.675rem;
      flex-basis: 20%;
    }

    .authorName {
      margin-right: 2rem;
    }
  }

  .messageText {
    margin-right: 2rem;
    flex-basis: 50%;
  }

  .sendDate {
    margin-right: 1.5rem;
  }

  .button {
    margin: 0 1.5rem !important;
  }

  a {
    color: var(--bg-green);
  }

  .divider {
    background-color: var(--buttonLightTheme) !important;
    border-width: 2px !important;
  }
}
