.button {
  border: none;
  background: transparent;
  border-radius: 50%;
}

.errorVariant {
  color: red;
}

.errorVariant:hover {
  color: #ff4e4e;
}

.errorVariant:active {
  color: #d63b3b;
}

.defaultVariant {
  color: #a7a7a7;
}

.defaultVariant:hover {
  color: lighten(#a7a7a7, 10%);
}

.defaultVariant:active {
  color: darken(#a7a7a7, 10%);
}

.text__button {
  color: var(--bg-green);
  padding: 14px;
  font-weight: 500;
}
