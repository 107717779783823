.card_main {
  display: grid;
  grid-template-columns: 0.1fr 1fr 1fr;
  padding-bottom: 24px;
}

.card_number_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card_title_container {
  padding: 20px 40px 0 52px;
}

.card_number {
  font-weight: 700;
  font-size: 64px;
  color: var(--text-default-color);
}

.card_title {
  margin: 0 0 12px 0;
  font-size: 26px;
  font-weight: 500;
  line-height: 31px;
  color: var(--count-light);
}

.card_description {
  font-size: 18px;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  color: var(--itemSidebarColor-light);
}

.card_icon_container {
  padding: 25px 0 0 0;
}

.cardIconImg {
  height: 330px;
  width: 100%;
}

.card_element {
  width: 2px;
  height: 297px;
  border-radius: 6px;
  background: var(--buttonLightTheme);
}
