.layout {
  &__main {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
  }
}

.backgroundLayout {
  background: url(../../src/assets/img/authorization/wave.svg) no-repeat bottom center;
  background-size: 100% auto;
  min-height: 777px;
  display: flex;
  justify-content: center;
  align-items: center;
}
