@import 'src/scss/mixins';

.statuses {
  padding-right: 1rem;

  ul {
    padding: 0;
  }

  @include for-tablet {
    margin-left: 2rem;
  }

  @include for-mobile {
    margin-left: 1rem;
  }
}

.status {
  margin-bottom: 1rem;
  display: flex;
  direction: row;
  align-items: center;
  font-weight: 500;
}

.status__color {
  width: 1rem;
  height: 1rem;
  margin-right: 6px;
  border-radius: 4px;
}
