.container {
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-bottom: 18px;

  color: var(--count-light);
  line-height: 28.13px;
  font-size: 24px;
  font-weight: 600;
  font-family: Roboto, sans-serif;
}

.period {
  color: var(--count-light);
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto, sans-serif;
  margin-bottom: 12px !important; // Перекрытие стилей от Project.module.scss
}

.chart_legend {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin-top: 14px;
  padding-left: 24px;
  &__container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    &__first_mark {
      width: 15px;
      border-top: 2px solid #eeba00;
    }
    &__second_mark {
      width: 15px;
      border-top: 2px dashed #189ced;
    }
  }
  &__label {
    color: var(--count-light);
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    margin-bottom: 0;
  }
}

.emptyData {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 64px 0 16px;
  & p {
    color: var(--count-dark);
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
  }
}
