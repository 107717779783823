@import 'src/scss/mixins';

.aboutBenefitsBlock {
  max-width: 1440px;
  margin: 0 auto;
  padding: 64px 72px;
  @include for-tablet {
    padding: 48px 36px;
  }
  @include for-mobile {
    padding: 32px 15px 48px 15px;
  }
}

.containerAboutCard {
  display: flex;
  flex-direction: column;
  padding: 16px 28px 24px 28px;
  @include for-mobile {
    padding: 10px 14px 16px 14px;
  }
}
.gridLayout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
  margin-top: 3rem;
  @include for-tablet {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @include for-mobile {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.cardWithImage {
  grid-column: span 2;
}
