.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  > p {
    margin: 0;
    padding: 0 32px;
    color: var(--count-light);
    font-weight: 500;
  }

  &__messages {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;
    padding: 0 32px;
    > div {
      &:not(:first-of-type) {
        padding-top: 24px;
      }
      padding-bottom: 24px;

      &:not(:last-of-type) {
        border-bottom: 1px solid var(--border-gray);
      }
    }
  }
}
