.container {
  padding: 1.5rem 2rem 2rem 2rem;
  grid-column: span 2;
  margin-bottom: 3rem;

  @media (max-width: 1470px) {
    grid-column: auto;
  }
}

.title {
  margin-bottom: 1.5rem;
}

.select__label {
  color: #78858b;
}

.select {
  width: 320px !important;
  margin-bottom: 2rem !important;
}

.content {
  display: flex;
  justify-content: space-between;
}
