@import '../../scss/mixins';
.container {
  max-width: 1440px;
  margin: 0 auto;
}

.mainFooter {
  background: #39393a;
  padding: 40px 72px;
  @include for-tablet {
    padding: 32px 36px;
  }
  @include for-mobile {
    padding: 24px 15px;
  }
}

.footerBlock {
  display: flex;
  justify-content: space-between;
  @include for-mobile {
    flex-direction: column;
  }
}

.commonBlock {
  display: flex;
  justify-content: space-between;
  gap: 4rem;

  span {
    color: var(--bg-light);
    font-size: 14px;
    font-weight: 400;
  }
  span:hover {
    color: var(--bg-green);
    cursor: pointer;
  }
}

.gap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.gap15 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.logoBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  @include for-mobile {
    align-items: flex-start;
    margin-top: 31px;
  }
}

.logoFooter {
  @include for-tablet {
    width: 100%;
    height: 52px;
  }
  @include for-mobile {
    width: 153px;
    height: 44px;
    margin-bottom: 16px;
  }
}

.linkBlock {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;

  @include for-tablet {
    grid-template-columns: 1fr 1fr;
  }
  @include for-mobile {
    grid-template-columns: 1fr;
  }
}
