.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 24px;
  > h2 {
    color: var(--count-light);
    margin: 0;
    font-size: 26px;
    line-height: 31px;
  }
}
