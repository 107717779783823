@import 'src/scss/mixins';

.form__page__container {
  display: flex;
  flex-direction: row;

  @media (max-width: 1280px) {
    flex-direction: column;
    padding: 0 25% !important;
  }

  @media (max-width: 1024px) {
    padding: 0 10% !important;
  }

  max-width: max-content;
}

.form__page__title {
  h2 {
    font-size: 26px;
  }

  @media (min-width: 1280px) {
    max-width: 205px;
    margin-right: 3rem;
  }
}

.form__card {
  margin-bottom: 3rem !important;
  margin-right: 1.5rem !important;
}

.form__wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  width: 416px;

  @media (max-width: 1280px) {
    width: 360px;
  }

  @media (max-width: 1024px) {
    width: 330px;
  }

  @media (max-width: 768px) {
    width: 300px;
  }

  @media (max-width: 640px) {
    width: 240px;
  }

  @media (max-width: 475px) {
    width: 200px;
  }

  @media (max-width: 375px) {
    width: 170px;
  }
}

.form__field {
  width: 100% !important;
  margin-bottom: 1.5rem !important;
}

.headId__field {
  margin-bottom: 1rem !important;
}

.stack__field {
  margin-top: 1.5rem !important;
}

.checkbox {
  margin-bottom: 2rem !important;
}

.submit__button {
  margin-bottom: 0.5rem !important;
}

.titleInstanceName {
  margin-bottom: 29px;
}

.title {
  font-weight: 700;
  line-height: 140%;
  margin-right: 40px;
  margin-bottom: 0;
}

.blockMenu {
  display: flex;
  justify-content: space-between;
}

.card {
  padding: 2rem 2rem 4rem 2rem;
  margin-bottom: 55px;
}

.containerContent {
  height: 100%;
  margin-right: 1.5rem;
  margin-bottom: 55px;
}

.loadingBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContent {
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  height: 100% !important;
  @include for-tablet {
    height: auto !important;
  }
}

.adaptiveTableMUI {
  margin-top: 1.5rem;
  max-width: 740px;
}

.mainFilters {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.filters {
  display: flex;
  gap: 14px;
  margin-left: 8px;
}

.filterBtn {
  border: 1px solid var(--border-gray) !important;
  color: var(--count-light) !important;
  &:hover {
    color: var(--count-light) !important;
    border: 1px solid var(--text-light-green) !important;
    background: transparent !important;
  }
}

.hr {
  height: 1px;
  border-radius: 1px;
  margin: 0;
  background-color: var(--border-bottom-light);
}

.checkboxContainer {
  display: flex;
}

.checkboxBtn {
  border: 1px solid var(--count-dark);
  width: 20px;
  height: 20px;
  box-shadow: none;
  appearance: none;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: var(--bg-green);
    border: var(--bg-green);
  }

  &:checked::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 6px;
    width: 6px;
    height: 12px;
    border: solid var(--bg-light);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  @include for-tablet {
    display: none;
  }
}
