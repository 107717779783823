.status__list {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.statusName {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 1rem;
}

.status__color {
  height: 1rem;
  min-width: 1rem;
  border-radius: 4px;
  margin-right: 0.375rem;
}
