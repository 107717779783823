button {
  background: transparent;
  border: none;
}

.star:hover {
  svg path {
    fill: #eeba00;
  }
}

.active {
  svg path {
    fill: #eeba00;
  }

  &:hover {
    svg path {
      fill: #ffc701;
    }
  }
}
