.mainBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mainText {
  color: #78858b;
  font-weight: 500;
}
