.container {
  width: 100%;
}

.vacancyName {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  &__icon {
    transition: all 0.3s ease-in-out;
    path {
      stroke: #007236;
    }
    &_rotated {
      transform: rotate(180deg);
    }
  }
  p {
    color: var(--text-default-color);
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
  span {
    color: var(--text-default-color);
    font-size: 14px;
    font-weight: 500;
    padding: 3px 4px;
    border: 1px solid var(--text-default-color);
    border-radius: 4px;
    text-align: center;
    min-width: 24px;
    line-height: 18px;
    margin: 0 0 0 8px;
    display: inline-block;
  }
}

.user {
  text-decoration: none;
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.more {
  > button {
    max-height: 40px;
  }
}

.requiredExecutorsCount {
  background: #f0f0f0;
  border-radius: 4px;
  border: 1px solid var(--border-gray);
  display: flex;
  flex-direction: row;
  max-height: fit-content;
  margin: 12px 0;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  p {
    height: fit-content;
    font-size: 14px;
    line-height: 18px;
    color: var(--count-light);
    font-weight: 500;
  }
}
