.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 15px;
  h3 {
    margin: 0;
    color: var(--count-light);
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  button {
    max-width: fit-content;
    display: flex;
    padding: 0;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
