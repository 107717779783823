@import 'src/scss/mixins';

.tasks__block {
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 700;
    font-size: 26px;
    color: var(--count-light) !important;
  }

  .title {
    margin-bottom: 2rem;

    @include for-mobile {
      margin-bottom: 1rem;
    }
  }

  .gantt__chart {
    margin-bottom: 4rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    row-gap: 20px;
    column-gap: 39px;

    @include for-tablet {
      flex-direction: column;
      align-items: flex-start;
      margin-right: 1rem;
    }

    @include for-mobile {
      margin-right: 0;
    }
  }
}
