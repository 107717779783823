@import 'src/scss/mixins';

.card_number,
.card_title,
.card_description {
  font-family: Roboto, sans-serif;
}

.card_number_container {
  margin-bottom: 8px;
}

.card_title_container {
  margin-bottom: 18px;
  @include for-mobile {
    margin-bottom: 10px;
  }
}

.card_description_container {
  margin-bottom: 24px;
  @include for-tablet {
    margin-bottom: 12px;
  }
}

.card_img_container {
  margin-bottom: 40px;
  @include for-mobile {
    margin: 0;
  }
}

.card_number {
  color: var(--bg-green);
  font-weight: 700;
  font-size: 48px;
  @include for-mobile {
    font-size: 40px;
  }
}

.card_title {
  font-size: 26px;
  font-weight: 600;
  @include for-mobile {
    font-size: 21px;
  }
}

.card_description {
  font-weight: 400;
  color: var(--itemSidebarColor-light);
  font-size: 18px;
  @include for-mobile {
    font-size: 16px;
  }
}

.card_img {
  width: 100%;
  @include for-mobile {
    display: none;
  }
}
