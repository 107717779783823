@import '../../scss/mixins';

.toolbar {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding-left: 0 !important;
  @include for-mobile {
    padding: 0 !important;
  }
}

.headerBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.button_connect {
  background: #fff !important;
  color: var(--text-default-color) !important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 8px 14px !important;
  border-radius: 4px !important;
  &:hover {
    background: #e1e1e1 !important;
  }
  @include for-mobile {
    background: transparent !important;
    color: #fff !important;
    &:hover {
      background: transparent !important;
    }
  }
}

.button__login {
  border: 1px solid #fff !important;
  color: #fff !important;
  padding: 8px 14px !important;
  border-radius: 4px !important;
  margin-left: 20px !important;
  &:hover {
    background: rgba(255, 255, 255, 0.15) !important;
  }
  @include for-mobile {
    padding: 8px 10px !important;
    margin: 0 !important;
  }
}

.elements {
  display: flex;
  gap: 28px;
  align-items: center;
}

.link {
  color: var(--bg-light);
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  text-decoration: none;

  &:hover,
  &.active {
    color: var(--bg-light);
    cursor: pointer;
  }
}
