@import '../../../scss/mixins';
.bgBox {
  background: #f5f7fa;
  max-width: 360px;
  height: 495px;
  position: relative;
}

.mainTitle {
  background-image: url('../../../assets/img/specialist/bg-card.png');
  background-size: cover;
  padding: 13px 16px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-size: 1.25rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  color: var(--bg-light);
}

.subTitle {
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 19.6px !important;
  color: var(--bg-light);
}

.bodyCard {
  padding: 20px 24px 24px 24px;
  @include for-tablet {
    padding: 16px 16px 24px 16px;
  }
}

.price {
  color: #199b3e;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.allRates {
  font-size: 1rem;
  font-weight: 500;
  line-height: 140%;
  font-style: normal;
}

.description {
  padding: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 30px 0;
}

.rate {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.button {
  padding: 14px 27.5px !important;
}

.buttonBlock {
  position: absolute;
  bottom: 24px;
}
