.employee__block {
  margin: 2rem 0rem 4rem 0;

  h2 {
    font-size: 26px !important;
    font-weight: 700 !important;
    color: var(--count-light) !important;
  }

  .title {
    margin-bottom: 2rem;
  }
}
