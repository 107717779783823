@import 'src/scss/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 1.5rem 1.5rem 1.5rem;

  @include for-tablet {
    margin-left: 0;
  }

  .nav__back {
    margin-top: 1.5rem;
  }

  .container {
    display: flex;
    padding: 2.5rem 2rem;
    width: 100%;
    flex-direction: row;

    @include for-tablet {
      flex-direction: column;
    }

    h2 {
      font-weight: 700;
      font-size: 26px;
    }

    .title {
      margin-right: 4rem;

      @include for-tablet {
        margin-bottom: 1.5rem;
      }
    }

    .settings__content__wrapper {
      width: 100%;

      .settings__content {
        width: 100%;

        .statuses__settings {
          display: flex;
          flex-direction: row;
          margin-bottom: 1.5rem;

          .statuses__settings__title {
            font-weight: 500;
            color: var(--bg-green);
            margin-left: 0.5rem;
          }

          .statuses__settings__yandex {
            color: var(--bg-green);
          }
        }

        .budget_rule {
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;

          .budget_rule__title {
            margin-bottom: 0.75rem;
            color: var(--bg-green);
            font-weight: 500;

            &__inactive {
              margin-bottom: 0.75rem;
              color: var(--bg-green);
              font-weight: 500;
              opacity: 50%;
            }
          }
        }

        .buttons {
          margin-top: 1.5rem;

          .tracker {
            margin-bottom: 1.5rem;

            .tracker__title {
              margin-right: 1.5rem;
            }
          }

          .clouds__title {
            margin-right: 1.5rem;
          }
        }
      }
    }
  }
}
