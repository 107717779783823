@import 'src/scss/mixins';

.icon {
  width: 180px;
  height: 180px;
  color: var(--bg-light);
  font-size: 82px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;
}

.icon::before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Серый цвет оверлея */
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 4px;
}

.icon:hover::before {
  opacity: 1;
}

.default__icon {
  width: 180px;
  height: 180px;
  color: var(--bg-light);
  font-size: 82px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: var(--bg-pink);

  @include for-tablet {
    width: 130px;
    height: 130px;
    font-size: 70px;
  }

  @include for-mobile {
    width: 90px;
    height: 90px;
    font-size: 50px;
  }
}

.editIcon {
  position: absolute;
  top: -55px;
  right: 10px;
  cursor: pointer;
  z-index: 4;
  svg {
    path[fill] {
      fill: #ffffff;
    }
    path[stroke] {
      stroke: #ffffff;
    }
  }
}

.menuText {
  font-weight: 500;
  line-height: 1.125rem;
}

.menuItem {
  gap: 0.5rem;
  width: 15.5rem;
  padding: 0.75rem 0rem 0.75rem 0.75rem;
  svg {
    path[fill] {
      fill: #199b3e;
    }
    path[stroke] {
      stroke: #199b3e;
    }
  }
}

.circularProgressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
