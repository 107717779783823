.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  h2 {
    margin: 0;
    color: var(--count-light);
    font-size: 26px;
    font-weight: 500;
    line-height: 31px;
  }
}

.description {
  margin: 0 0 8px 0;
  color: var(--count-light);
}
