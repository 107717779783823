.messageDate {
  color: #78858b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
}

.listMessages {
  height: 600px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
}
