.status {
  width: fit-content;
  padding: 5px 35px;
  max-height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin: 10px 12px;
  border-radius: 1rem;
}
