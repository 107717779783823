@import 'src/scss/mixins';

.main {
  padding: 0 72px;
  background: var(--bg-light-grayish);
  @include for-tablet {
    padding: 0 36px;
  }
  @include for-mobile {
    padding: 0 15px;
  }
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.yandexBanner {
  display: flex;
  align-items: center;
  @include for-tablet {
    padding-top: 45px;
    background-image: url('../../assets/img/yandexPublic/yandexTabletBg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
  }

  @include for-mobile {
    background-image: none;
  }
}

.partners {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 14px;

  &__subTitle {
    font-family: Roboto, sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 14px;
    @include for-mobile {
      font-size: 24px;
    }

    &__sign {
      font-size: 20px;
      line-height: 26px;
      font-weight: 400;
      margin: 0;
    }

    &__color {
      color: var(--text-default-color);
    }
  }
}

.conditions {
  &__btn {
    margin-top: 50px;
  }
}

.yandexService {
  margin-top: 80px;
}

.listService,
.listTracker {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.yandexItem,
.yandexBenefits {
  margin-top: 110px;

  @include for-mobile {
    margin-top: 40px;
  }
}

.yandexBenefits,
.cardsInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.yandexBenefits {
  gap: 64px 51px;
  @include for-tablet {
    grid-template-columns: 1fr;
  }
}

.cardsInfo {
  margin-top: 100px;
  gap: 25px;
  @include for-tablet {
    grid-template-columns: 1fr;
  }
  @include for-mobile {
    margin-top: 40px;
  }
}

.card,
.greenCard,
.payYandex {
  box-shadow: 5px 5px 30px 0 rgba(111, 111, 111, 0.16);
}

.card {
  background: var(--bg-light);
  padding: 32px 36px;
  border-radius: 12px;
  @include for-mobile {
    padding: 16px;
  }
  &__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    margin: 0 0 37px 0;
    color: var(--count-light);
    @include for-mobile {
      font-size: 26px;
    }
  }
  &__description {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--count-light);
  }
}

.results {
  display: flex;
  gap: 18px;
  margin-bottom: 50px;
  &__data {
    margin: 0;
    color: var(--text-hover-color);
    font-weight: 700;
    font-size: 56px;
    line-height: 44.8px;
    @include for-mobile {
      font-size: 36px;
    }
  }

  &__opinion {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
}

.greenCard {
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
  background: linear-gradient(#0c882f, #199b3e);
  border-radius: 12px;
  position: relative;
  @include for-mobile {
    margin-top: 40px;
  }
}

.infoGreenCard {
  padding: 32px 36px;
  color: var(--bg-light);
}

.titleCard,
.descriptionCard {
  margin: 0;
}

.titleCard {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  font-family: Roboto, sans-serif;
  margin-bottom: 4px;
}

.descriptionCard {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  font-family: Roboto, sans-serif;
}

.firstCard,
.listService,
.titleYandexTracker {
  margin-bottom: 25px;
}

.secondCard {
  margin-bottom: 20px;
}

.titleTool {
  margin: 70px 0 95px 0;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 40.5px;
  color: var(--text-default-color);

  @include for-tablet {
    margin: 46px 0 50px 0;
  }

  @include for-mobile {
    font-size: 25px;
    margin: 30px 0 48px 0;
    line-height: 33.75px;
  }
}

.egileTracker {
  display: flex;
  gap: 40px;
  @include for-tablet {
    flex-direction: column;
  }
  @include for-mobile {
    flex-direction: column;
  }
}

.titleProcess {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 18px;

  @include for-mobile {
    margin-bottom: 16px;
  }
}

.titleTracker {
  margin-bottom: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.listTracker {
  margin-bottom: 20px;
}

.descriptionTracker {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--count-light);
}

.projectTeam {
  display: flex;
  margin-top: 120px;
  @include for-tablet {
    flex-direction: column;
  }
  @include for-mobile {
    flex-direction: column;
    margin-top: 40px;
  }
}

.titleYandexTracker {
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  font-family: Roboto, sans-serif;
  @include for-mobile {
    font-size: 26px;
  }
}

.job {
  margin-top: 90px;
  display: flex;
  @include for-tablet {
    flex-direction: column;
  }
  @include for-mobile {
    flex-direction: column;
    margin-top: 40px;
  }
}

.subTitleTool {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.descriptionJob {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
}

.programs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include for-tablet {
    padding: 16px;
    text-align: center;
  }
  @include for-mobile {
    padding: 12px;
  }
}

.titlePrograms {
  color: var(--bg-light);
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 30px;

  @include for-mobile {
    font-size: 16px;
  }
}

.leftCorner {
  border-radius: 12px;
  @include for-tablet {
    display: none;
  }
  @include for-mobile {
    display: none;
  }
}

.programsDescription {
  color: var(--bg-light);
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin: 0;
  text-align: center;
  @include for-mobile {
    font-size: 16px;
  }
}

.rightCorner {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 12px;
}

.ratesBlocks {
  display: flex;
  gap: 24px;
  margin-bottom: 90px;
  @include for-tablet {
    flex-direction: column;
  }
}

.rates360 {
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  margin-bottom: 48px;
}

.payYandex {
  border: 1px solid var(--bg-light-grayish);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  background: var(--bg-light);
  width: fit-content;

  @include for-tablet {
    padding: 16px;
  }
}

.blockPayYandex {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @include for-mobile {
    margin-top: 40px;
  }
}

.payTitle {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}

.payBlock {
  display: flex;
  gap: 40px;
  margin-bottom: 32px;
  @include for-mobile {
    flex-direction: column;
  }
}

.sumBlock {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}

.inputSum,
.inputResult {
  outline: none;
  padding: 17px 16px;
  font-size: 28px;
  font-weight: 500;
  line-height: 30px;
  border: 1px solid var(--bg-gray);
  border-radius: 8px;
  background: var(--bg-light-grayish);
}

.inputSum,
.title,
.titleProcess,
.titleYandexTracker,
.subTitleTool,
.payTitle,
.label {
  color: var(--count-light);
}

.inputResult {
  color: var(--color-red);
}

.btnPayBlock {
  margin-top: 100px;
  margin-bottom: 139px;
}

.btnPayYandex {
  padding: 24px 144.5px !important;
  @include for-mobile {
    padding: 6px 16px !important;
  }
}

.circle__blue,
.circle__yellow {
  width: 326px;
  height: 326px;
  opacity: 50%;
  border-radius: 50%;
  filter: blur(153px);
  position: absolute;
  bottom: 0;
}

.circle__blue {
  left: 0;
  background: #4ba7c1;
}

.circle__yellow {
  right: 0;
  background: #e8a056;
}

.yandexServiceIcons {
  display: block;
  @include for-tablet {
    display: none;
  }
  @include for-mobile {
    display: none;
  }
}

.pictureStyle {
  max-width: 730px;
  @include for-tablet {
    width: 100%;
    height: 100%;
  }
}

.form {
  background: var(--bg-light-grayish);

  .formMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 116px;
    @include for-tablet {
      padding: 0 36px 100px;
    }
    @include for-mobile {
      padding: 0 15px 100px;
    }
  }
}

.title__color {
  color: var(--text-hover-color);
}
