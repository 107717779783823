@import 'src/scss/mixins';

.notificationBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include for-mobile {
    flex-direction: column;
  }
}

.blockAgreeBtn {
  @include for-mobile {
    width: 100%;
  }
}

.agreeBtn {
  @include for-mobile {
    width: 100%;
  }
}

.mainNotification {
  @include for-mobile {
    margin-bottom: 13px;
  }
}

.contentText {
  color: var(--count-light);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
