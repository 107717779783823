.form__page__container {
  display: flex;
  flex-direction: row;
  padding: 40px 0;
  @media (max-width: 1280px) {
    flex-direction: column;
    padding: 0 25% !important;
  }

  @media (max-width: 1024px) {
    padding: 0 10% !important;
  }

  max-width: max-content;
}

.form__page__title {
  font-weight: 700;
  font-size: 26px;
  line-height: 36.4px;
  margin-right: 76px;
}

.form__card {
  margin: 1.5rem 0 3rem 0 !important;
}

.form__wrapper {
  display: flex;
  flex-direction: column;
  width: 416px;

  @media (max-width: 1280px) {
    width: 360px;
  }

  @media (max-width: 1024px) {
    width: 330px;
  }

  @media (max-width: 768px) {
    width: 300px;
  }

  @media (max-width: 640px) {
    width: 240px;
  }

  @media (max-width: 475px) {
    width: 200px;
  }

  @media (max-width: 375px) {
    width: 170px;
  }
}

.form__field {
  width: 100% !important;
  margin-bottom: 1.5rem !important;
}

.headId__field {
  margin-bottom: 1rem !important;
}

.stack__field {
  margin-top: 1.5rem !important;
}

.checkbox {
  margin-bottom: 2rem !important;
}

.submit__button {
  margin-bottom: 0.5rem !important;
}

.visibility {
  display: flex;
  position: relative;
  align-items: baseline;
  gap: 11px;
  &__tooltip {
    position: absolute;
    right: -28px;
    top: 18px;
  }
}
