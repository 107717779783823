@import '../../scss/mixins';

.main {
  background: var(--bg-light);
  padding: 56px 72px 120px 72px;
  @include for-tablet {
    padding: 56px 36px 70px 36px;
  }

  @include for-mobile {
    padding: 48px 15px 39px 15px;
  }
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.titlePlatform {
  font-weight: 500;
  font-size: 38px;
  line-height: 45px;
  font-family: Roboto, sans-serif;
  margin-bottom: 47px;
  @include for-tablet {
    margin-bottom: 39px;
  }
  @include for-mobile {
    margin-bottom: 24px;
    font-size: 26px;
    font-weight: 500;
    line-height: 31px;
  }
}

.cardsLayout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  @include for-tablet {
    justify-content: flex-start;
  }
  @include for-mobile {
    gap: 16px;
  }
}

.cardPlatform {
  background: var(--bg-light-grayish);
  min-height: 280px;
  width: 31.6%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-light-gray);
  @include for-tablet {
    min-height: 226px;
    width: 48%;
  }
  @include for-mobile {
    min-height: 150px;
    width: 100%;
  }
}

.logo {
  @include for-tablet {
    padding: 10px;
  }
}

.logoAnalitics {
  @include for-tablet {
    width: 80px;
  }
  @include for-mobile {
    width: 60px;
  }
}

.logoBot {
  @include for-tablet {
    width: 95px;
  }
  @include for-mobile {
    width: 71px;
  }
}

.cardText {
  font-weight: 500;
  font-size: 22px;
  line-height: 25.28px;
  text-align: center;
  margin-top: 23px;

  @include for-mobile {
    margin-top: 13px;
    font-size: 18px;
    line-height: 23.4px;
  }
}
