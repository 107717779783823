.modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.container {
  max-width: 480px !important;
  width: 100% !important;
}

.modal__footer {
  margin-top: 56px;
  display: flex;
  justify-content: flex-end;
}
