.common__analytics {
  margin-bottom: 4rem;

  &__head {
    display: flex;

    h2 {
      font-weight: 700;
      font-size: 26px;
      color: var(--count-light) !important;
    }
  }

  &__links {
    margin-bottom: 1.5rem;

    li {
      button {
        color: var(--bg-green);
        padding-top: 1rem;
      }
    }
  }

  &__blocks {
  }
}
