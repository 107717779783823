.container {
  max-width: 1584px;
  padding-left: 72px;
  padding-right: 72px;
  margin: 0 auto;
}
.wrapper {
  min-height: calc(100vh - 400px);
  padding-bottom: 110px;
}
.banner {
  min-height: 450px;
  background-size: 100% auto;
  background-position: 100% 15%;
  background-repeat: no-repeat;
  margin-bottom: 40px;
  &__backdrop {
    display: flex;
    align-items: center;
    padding: 96px 0 110px 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
  & h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 110%;
    color: #ffffff;
  }
}
.date {
  color: #ffffff;
}
.tag {
  background-color: #39393a;
  border-radius: 3px;
  padding: 7px 8px;
  min-width: 77px;
  font-size: 14px;
  color: #ffffff;
  transition: all 0.3s ease;
}
.content {
  margin-bottom: 82px;
  & a {
    text-decoration: underline !important;
  }
  & img {
    max-width: 100%;
    height: auto;
    border-radius: 3px;
  }
}
.navLink {
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: #78858b;
}
.breadcrumbs {
  & a {
    display: inline-flex;
    align-items: center;
  }
  & a,
  span {
    font-size: 14px;
    line-height: 140%;
    color: #78858b;
    &:not(:last-child) {
      margin-right: 8px;
      &::after {
        margin-left: 8px;
        content: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_18501_52931)'%3E%3Cpath d='M1.25083 0.750143L1.25083 5.25014C1.25097 5.2957 1.26354 5.34036 1.28717 5.37931C1.31081 5.41826 1.34462 5.45003 1.38497 5.47119C1.42531 5.49236 1.47067 5.50212 1.51615 5.49942C1.56163 5.49672 1.60551 5.48168 1.64308 5.45589L4.89308 3.20589C5.02783 3.11264 5.02783 2.88814 4.89308 2.79464L1.64308 0.544643C1.60559 0.518599 1.56169 0.503327 1.51613 0.500485C1.47057 0.497642 1.42511 0.507339 1.38468 0.528522C1.34424 0.549704 1.31039 0.581562 1.28679 0.620634C1.2632 0.659706 1.25076 0.704498 1.25083 0.750143Z' fill='%2378858B' /%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_18501_52931'%3E%3Crect width='6' height='6' fill='white' transform='translate(6) rotate(90)' /%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        line-height: 105%;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding-left: 32px;
    padding-right: 32px;
  }
  .banner {
    margin-bottom: 32px;
    min-height: 380px;
    &__backdrop {
      padding: 42px 0 76px 0;
    }
    & h1 {
      font-size: 40px;
      line-height: 140%;
    }
  }
  .date {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .banner {
    margin-bottom: 24px;
    min-height: 320px;
    &__backdrop {
      padding: 50px 0 52px 0;
    }
    & h1 {
      font-weight: 500;
      font-size: 26px;
      line-height: 119%;
    }
  }
  .date {
    font-size: 14px;
  }
}
