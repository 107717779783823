.spent__time__value {
  color: var(--bg-green);
  margin-right: 0.25rem;
  font-weight: 500;
}

.avatar {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  font-size: 18px;
  pointer-events: none;
  margin-right: 10px;
}
