.templatesLink {
  color: var(--bg-green);
  &:hover {
    color: var(--text-light-green);
  }
}

.detailTemplatesMain {
  margin-top: 16px;
}

.detailTemplates {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.detailTemplatesTitle,
.detailTemplatesResult {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.detailTemplatesTitle {
  font-weight: 400;
  line-height: 24px;
  color: var(--count-dark);
}

.detailTemplatesResult {
  font-weight: 500;
  line-height: 22.4px;
  color: var(--count-light);
}

.btns {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.btnCancel {
  font-family: Roboto, sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 10px 20px !important;
  color: var(--cancel-color) !important;
  &:hover {
    background-color: transparent !important;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
}

.templateTable {
  max-width: 841px;
  margin-top: 1.5rem;
}
