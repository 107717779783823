@import 'src/scss/mixins';

.container,
.nav,
.profile {
  display: flex;
  flex-direction: column;
}

.mobileProfile {
  display: none;
}

@media (max-width: 768px) {
  .mobileProfile {
    display: block;
    img {
      width: 40px;
      height: 40px;
    }
    p {
      margin: 0;
    }
    span {
      color: gray;
    }
    button {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 12px 0;
      border: none;
      background-color: transparent;
      text-align: left;
      font-weight: 600;
    }
  }

  .mobileContainer {
    display: flex;
    gap: 8px;
  }
}

.container {
  padding: 12px;
}

.nav {
  height: 100%;
}

.sun {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-image: url('/assets/img/sun.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.moonDark {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-image: url('/assets/img/moon.svg');
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
}

.themeSwitcherButton {
  width: 65px;
  height: 32px;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  position: relative;
  top: 11rem;
  border: 1px solid var(--buttonLightTheme);
}

.themeSwitcherButton.dark {
  border: var(--buttonDarkTheme);
  background-color: #39393a;
}

.circleSwitch {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--bg-hover-circleSwitcher);
  transform: translateX(0);
  transition: var(--transition);
  z-index: 3;

  &:hover {
    background-color: var(--bg-hover-circleSwitcher);
  }
}

.visible {
  transform: translateX(-31.5px);
}

.sidebar {
  position: fixed;
  width: 188px;
  height: 100vh;
  background: var(--bg-light);
  @media (max-width: 576px) {
    transform: translateX(-268px);
    transition: transform 0.3s ease-in-out;
    z-index: 3;
  }
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar.dark {
  background: var(--bg-dark);
}

.sidebarList {
  padding-top: 66px !important;
  @include for-tablet {
    padding: 0 !important;
  }
}

.closeIcon {
  margin: 14px;
}
